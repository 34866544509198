import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    
    localStorage.removeItem('food-user');
    localStorage.removeItem('favorites');
    navigate('/');
  };

  return { logout };
};

export default useLogout;
