import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../utility/Loader";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Notifications/GetNotifications",
          {
            userId: 13479,
          }
        );

        if (
          response.data.notifications &&
          response.data.notifications.length > 0
        ) {
          setNotifications(response.data.notifications);
        } else {
          setNotifications([]);
        }
      } catch (error) {
       
        setNotifications([]);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <section className="qa-list">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="subheading d-flex align-items-center justify-content-center gap-3">
              Messages{" "}
              <span className="customheadingspan">
                ({notifications.length})
              </span>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="msg-notifications">
            {notifications.length === 0 ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <p className="paraCustom">No Notifications</p>
              </div>
            ) : (
              <ul>
                {notifications.map((notification, index) => (
                  <li
                    key={index}
                    className={notification.read ? "read-msg" : ""}
                  >
                    <span>{notification.title}</span> {notification.message}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notifications;
