import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../utility/Loader";

const WeeklySalesPage = () => {
  const [weeklyAdDetails, setweeklyAdDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchweeklyAdDetails = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/WeeklyAd/GetWeeklyAds",
          {
            userId: 13479,
          },
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );

        if (
          response.data.weeklyAdDetails &&
          response.data.weeklyAdDetails.length > 0
        ) {
          setweeklyAdDetails(response.data.weeklyAdDetails);
        } else {
          setweeklyAdDetails([]);
        }
      } catch (error) {
      
        setweeklyAdDetails([]);
      } finally {
        setLoading(false);
      }
    };

    fetchweeklyAdDetails();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <section className="qa-list">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="subheading d-flex align-items-center justify-content-center gap-3">
              Weekly Ads{" "}
              <span className="customheadingspan">
                ({weeklyAdDetails.length})
              </span>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="msg-notifications">
            {weeklyAdDetails.length === 0 ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <p className="paraCustom">No weekly ad found.</p>
              </div>
            ) : (
              <ul>
                {weeklyAdDetails.map((notification, index) => (
                  <li
                    key={index}
                    className={notification.read ? "read-msg" : ""}
                  >
                    <span>{weeklyAdDetails.title}</span> {weeklyAdDetails.message}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeeklySalesPage;
