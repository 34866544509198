import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import setting1 from "../assets/images/settings/1.png";
import setting2 from "../assets/images/settings/2.png";
import settingBarChart from "../assets/images/settings/bar-chart-big.png";
import settingEllipsis from "../assets/images/settings/rectangle-ellipsis.png";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import useClearFavorites from '../hooks/useClearFavorites';

const Setting = () => {
  const navigate = useNavigate();
  const { clearFavorites, loading, error, response } = useClearFavorites();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleClearFavorites = () => {
    clearFavorites(13479);
  };

  useEffect(() => {
    if (response) {
      setShowSuccessMessage(true);
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timeout on component unmount
    }
  }, [response]);

  return (
    <div>
      <section className="setting-section">
        <div className="container">
          <div className="row">
            <h2 className="text-center subheading">Settings</h2>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-10 mx-auto">
              <div className="row justify-content-md-center align-items-md-center">
                <div className="col-sm-12 col-md-4">
                  <div className="settings-options" onClick={handleClearFavorites}>
                    <img src={setting1} alt="Clear Favorites" />
                    <h5>Clear Favorites</h5>
                    <p>You can clear your favorite list from here</p>
                    {loading ? <p>Loading...</p> : (
                      <HiOutlineArrowLongRight style={{ fontSize: "45px", color: "#D4AC0D" }} />
                    )}
                    {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
                    {showSuccessMessage && <p>Favorites cleared successfully!</p>}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="settings-options" onClick={() => navigate("/wishlist")}>
                    <img src={setting2} alt="Update Products" />
                    <h5>Update products</h5>
                    <p>You can update or delete your listed products from here</p>
                    <HiOutlineArrowLongRight style={{ fontSize: "45px", color: "#D4AC0D" }} />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="settings-options">
                    <img src={settingBarChart} alt="Update Weekly Sales" />
                    <h5>Update weekly sales</h5>
                    <p>You can update your weekly sale list</p>
                    <HiOutlineArrowLongRight style={{ fontSize: "45px", color: "#D4AC0D" }} />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="settings-options" onClick={() => navigate("/settings/reset-pin")}>
                    <img src={settingEllipsis} alt="Reset Pin" />
                    <h5>Reset Pin</h5>
                    <p>You can reset your pin from here</p>
                    <HiOutlineArrowLongRight style={{ fontSize: "45px", color: "#D4AC0D" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Setting;
