import React from "react";
import vegImg from "../../assets/images/departments/veg.png";
import fruImg from "../../assets/images/departments/fru.png";
import bakImg from "../../assets/images/departments/bak.png";
import foodImg from "../../assets/images/departments/food.png";
import nutsImg from "../../assets/images/departments/nuts.png";
import miscImg from "../../assets/images/departments/misc.png";
import { useNavigate } from "react-router-dom";
const HomeDepartments = () => {
  const navigate = useNavigate();
  return (
    <section className="departments">
      <div className="container">
        <div className="row">
          <h2 className="subheading text-center">Our departments</h2>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-11 mx-auto">
            <div className="row justify-content-md-center align-items-md-center">
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/3")}
                >
                  <img src={vegImg} alt="Vegetables" />
                  <h3>Vegetables</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/2")}
                >
                  <img src={fruImg} alt="Fruit" />
                  <h3>Fruit</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/1")}
                >
                  <img src={bakImg} alt="Bakery" />
                  <h3>Bakery</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/5")}
                >
                  <img src={foodImg} alt="Hot foods" />
                  <h3>Hot foods</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/4")}
                >
                  <img src={nutsImg} alt="Nut" />
                  <h3>Nut</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div
                  className="departments-category"
                  onClick={() => navigate("/departments/subdepartment/6")}
                >
                  <img src={miscImg} alt="Misc" />
                  <h3>Misc</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeDepartments;
