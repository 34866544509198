import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logoHeader from "../assets/images/logo-header.png";
import userIcon from "../assets/images/user-icon.svg";
import notificationIcon from "../assets/images/notifi.svg";
import LanguageModal from "./LanguageModal";
import { MdKeyboardArrowDown } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import useLogout from "../hooks/useLogout";
import { decryptData, importKey } from '../utility/Crypto';

function Navbar() {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useLogout();

  const handleClick = (event) => {
    if (
      event.target.closest(".modal") ||
      event.target.classList.contains("modal-backdrop")
    ) {
      return;
    }
    setOpen(!open);
  };

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [menuRef]);

  useEffect(() => {
    const fetchFirstName = async () => {
      const storedKey = sessionStorage.getItem('cryptoKey');
      if (storedKey) {
        const key = await importKey(new Uint8Array(JSON.parse(storedKey)));
        const encryptedFirstName = JSON.parse(sessionStorage.getItem('firstName'));
        if (encryptedFirstName) {
          try {
            const decryptedFirstName = await decryptData(
              new Uint8Array(encryptedFirstName.encryptedData),
              new Uint8Array(encryptedFirstName.iv),
              key
            );
            setFirstName(decryptedFirstName);
          } catch (error) {
            console.error('Error decrypting user data:', error);
          }
        }
      }
    };
    fetchFirstName();
  }, []);

  const isActive = (basePath) => location.pathname.startsWith(basePath);

  return (
    <div>
      <header className="header">
        <div className="row align-items-center main-menu">
          <div className="col-3 col-sm-2 col-md-1">
            <img src={logoHeader} className="header-logo" alt="Logo" />
          </div>
          <div className="col">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${isActive("/home") ? "active" : ""}`}
                        aria-current="page"
                        href=" "
                        onClick={() => navigate("/home")}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${isActive("/products") ? "active" : ""}`}
                        href=" "
                        onClick={() => navigate("/products")}
                      >
                        Products
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${isActive("/departments") ? "active" : ""}`}
                        href=" "
                        onClick={() => navigate("/departments")}
                      >
                        Departments
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${isActive("/plutest") ? "active" : ""}`}
                        href=" "
                        onClick={() => navigate("/plutest")}
                      >
                        PLU Test
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => navigate("/weeklysales")}>
                      <a
                        className={`nav-link ${isActive("/weeklysales") ? "active" : ""}`}
                        href=" "
                      >
                        Weekly Sales
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => navigate("/favorites")}>
                      <p className={`nav-link ${isActive("/favorites") ? "active" : ""}`}>
                        Favorite
                      </p>
                    </li>
                    <li className="nav-item" onClick={() => navigate("/wishlist")}>
                      <a
                        href=" "
                        className={`nav-link ${isActive("/wishlist") ? "active" : ""}`}
                      >
                        Wishlist
                      </a>
                    </li>
                  </ul>
                </div>
                <ul className="d-flex align-items-center nav-right">
                  <div
                    className="relative d-flex align-items-center user-profile"
                    ref={menuRef}
                    onClick={handleClick}
                  >
                    <img src={userIcon} alt="User" /> {firstName}
                    <MdKeyboardArrowDown />
                    <div className={`dropdown-Menu ${open ? "active" : "inactive"}`}>
                      <ul className="whitepad">
                        <li
                          className="droptext"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Language
                        </li>
                        {location.pathname === "/settings" ? (
                          <li className="droptext" onClick={() => navigate("/home")}>
                            Home
                          </li>
                        ) : (
                          <li className="droptext" onClick={() => navigate("/settings")}>
                            Settings
                          </li>
                        )}
                        <li className="droptext" onClick={handleLogout}>
                          Logout
                        </li>
                      </ul>
                    </div>
                    <LanguageModal />
                  </div>

                  {/* Bell Notifications */}
                  <li
                    className="nav-item"
                    onClick={() => navigate("/notifications")}
                    style={{ cursor: "pointer" }}
                  >
                    <a className="nav-link disabled" aria-disabled="true" href=" ">
                      <img src={notificationIcon} alt="Notification" />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
