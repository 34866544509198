import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    // Ensure there are always 5 visible page numbers
    if (currentPage <= 3) {
      endPage = Math.min(4, totalPages);
    } else if (currentPage >= totalPages - 2) {
      startPage = Math.max(totalPages - 4, 1);
    }

    if (startPage > 2) {
      pageNumbers.push(
        <li key="start-ellipsis" className="page-item">
          <span className="page-item">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className="page-item">
          <button
            className={`page-links ${i === currentPage ? "is-active" : ""}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="end-ellipsis" className="page-item">
          <span className="page-item">...</span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="row">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {renderPageNumbers()}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
