import React, { useEffect } from "react";
import banana from "../assets/images/product/banana1.png";
import apple from "../assets/images/product/apple-1.jpg";
import tomato from "../assets/images/product/tomato.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

import { LuLeaf } from "react-icons/lu";
import { PiShoppingCartSimpleBold } from "react-icons/pi";

const TopSellers = () => {
  useEffect(() => {
    // Ensure jQuery and OwlCarousel are loaded
    if (window.jQuery && window.jQuery.fn.owlCarousel) {
      window.jQuery(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [
          '<button class="nav-preve">⟵</button>',
          '<button class="nav-nextr">⟶</button>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 3,
          },
        },
      });
    }
  }, []);

  return (
    <section className="weekly-sales products-slides">
      <div className="container">
        <div className="row">
          <h2 className="text-center subheading">Top Sellers</h2>
        </div>
        <div className="product-slider">
          <div className="row">
            <div className="col-md-12">
              <div className="owl-carousel product-home-carousel">
                <div className="item ">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img src={banana} className="img-fluid" alt="Banana" />
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        4011 <span className="product-name">Banana</span>
                      </h3>
                      <ul className="m-card-content-product-add productAdd">
                        <li>
                          <a href=" ">
                            <i className="fa fa-tag"></i>
                          </a>
                        </li>
                        <li>
                          <a href=" ">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img src={apple} className="img-fluid" alt="Product" />
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        3524{" "}
                        <span className="product-name">
                          Tangerines / Mandarins
                        </span>
                      </h3>
                      <ul className="m-card-content-product-add productAdd">
                        <li>
                          <a href=" ">
                            <i className="fa fa-tag"></i>
                          </a>
                        </li>
                        <li>
                          <a href=" ">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img src={tomato} className="img-fluid" alt="Product" />
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        3524{" "}
                        <span className="product-name">
                          Tangerines / Mandarins
                        </span>
                      </h3>
                      <ul className="m-card-content-product-add productAdd">
                        <li>
                          <a href=" ">
                            <i className="fa fa-tag"></i>
                          </a>
                        </li>
                        <li>
                          <a href=" ">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img src={banana} className="img-fluid" alt="Product" />
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        3524{" "}
                        <span className="product-name">
                          Tangerines / Mandarins
                        </span>
                      </h3>
                      <ul className="m-card-content-product-add productAdd">
                        <li>
                          <a href=" ">
                            <i className="fa fa-tag"></i>
                          </a>
                        </li>
                        <li>
                          <a href=" ">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img src={tomato} className="img-fluid" alt="Product" />
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        3524{" "}
                        <span className="product-name">
                          Tangerines / Mandarins
                        </span>
                      </h3>
                      <ul className="m-card-content-product-add productAdd">
                        <li>
                          <a href=" ">
                            <i className="fa fa-tag"></i>
                          </a>
                        </li>
                        <li>
                          <a href=" ">
                            <i className="fa-solid fa-heart"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Repeat for other items */}
              </div>
              <div className="d-flex justify-content-center pt-5">
                <button type="button" className="btn-one text-center">
                  view all
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSellers;
