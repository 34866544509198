import React, { useState } from "react";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { LuLeaf } from "react-icons/lu";
import axios from "axios";
import Loader from "../utility/Loader";
import ProductModal from "./ProductModal";
import defaultImage from "../assets/images/noImage.jpg";
import imgLoader from "../assets/images/imgLoader.png";
import { GoTag } from "react-icons/go";
import ScrollToTop from "./ScrollTotop";
import DeleteFavoriteModal from "./DeleteFavoriteModal";
import { Modal } from "bootstrap";
import WishlistModal from "./WishlistModal";

const FavoritesGrid = ({ products = [], onFavoriteUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPluCode, setSelectedPluCode] = useState(null);
  const [loadedImages, setLoadedImages] = useState({});

  const handleFavoriteClick = (product) => {
    setSelectedProduct(product);
    const modal = new Modal(document.getElementById('deleteFavoriteModal'));
    modal.show();
  };

  const handleWishlistClick = (pluCode) => {
    setSelectedPluCode(pluCode);
    const wishlistModal = new Modal(document.getElementById("wishlistModal"));
    wishlistModal.show();
  };

  const handleConfirmUnfavorite = async () => {
    if (!selectedProduct) return;

    setLoading(true);
    try {
      const updatedProducts = products.map((p) => ({
        IsDeleteFlag: p.ProductId === selectedProduct.ProductId,
        ProductId: p.ProductId,
        UserNumber: "13479",
      }));

      const index = updatedProducts.findIndex(
        (p) => p.ProductId === selectedProduct.ProductId
      );
      const [unfavoritedProduct] = updatedProducts.splice(index, 1);
      updatedProducts.unshift(unfavoritedProduct);

      await axios.post(
        process.env.REACT_APP_API_URL + "/api/MakeFavorite/MakeFavorite",
        updatedProducts,
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

      const newFavorites = products.filter(
        (p) => p.ProductId !== selectedProduct.ProductId
      );

      onFavoriteUpdate(newFavorites);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    } finally {
      setLoading(false);
      setSelectedProduct(null);
    }
  };

  const handleImageLoad = (productId) => {
    setLoadedImages(prev => ({ ...prev, [productId]: true }));
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <section className="products-list pt-5">
        <div className="container">
          <div className="row">
            {products.length > 0 ? (
              products.map((product) => (
                <div key={product.ProductId} className="col-sm-6 col-md-4">
                  <div className="m-card">
                    <div className="m-card-img">
                      <img
                        src={loadedImages[product.ProductId] 
                          ? (product.ImageName === "/images/no-image-available.png"
                              ? defaultImage
                              : product.ImageName)
                          : imgLoader}
                        alt={product.Description}
                        className="img-fluids"
                        onLoad={() => handleImageLoad(product.ProductId)}
                        style={{ display: loadedImages[product.ProductId] ? 'block' : 'none' }}
                      />
                      {!loadedImages[product.ProductId] && (
                        <img
                          src={imgLoader}
                          alt="Loading..."
                          className="img-fluids"
                        />
                      )}
                      <div className="m-card-img-cart">
                        <LuLeaf />
                        <PiShoppingCartSimpleBold />
                      </div>
                    </div>
                    <div className="m-card-content">
                      <h3 className="m-card-content-product-code">
                        {product.PluCode}{" "}
                        <span className="product-name">
                          {product.Description}
                        </span>
                      </h3>
                      <ul className="m-card-content-product-adds">
                        <GoTag 
                          style={{ cursor: "pointer" }}
                          onClick={() => handleWishlistClick(product.PluCode)}
                        />
                        <i
                          className="fa-solid fa-heart"
                          style={{
                            color: "yellow",
                            fontSize: "50px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleFavoriteClick(product)}
                        ></i>
                      </ul>
                    </div>
                  </div>
                  <ProductModal product={product} />
                </div>
              ))
            ) : (
              <div className="col-sm-12 col-md-12 text-center">
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "2.5rem",
                    lineHeight: "2.5rem",
                    color: "#545454",
                  }}
                >
                  No favorite products found.
                </p>
              </div>
            )}
          </div>
          <ScrollToTop />
        </div>
      </section>
      <DeleteFavoriteModal onConfirm={handleConfirmUnfavorite} />
      <WishlistModal pluCode={selectedPluCode} />
    </>
  );
};

export default FavoritesGrid;