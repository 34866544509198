import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "bootstrap";
import Pagination from "./Pagination";
import ProductModal from "./ProductModal";
import Loader from "../utility/Loader";
import ProductCard from "./ProductCard";
import WishlistModal from "./WishlistModal";
import ScrollToTop from "../components/ScrollTotop";
import useAllProducts from "../hooks/useAllProducts";

const ProductList = ({
  setProductCount,
  searchTerm,
  activeAlphabet,
  handleResetClick,
  products,
}) => {
  const { loading, error } = useAllProducts();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPluCode, setSelectedPluCode] = useState(null);
  const [productList, setProductList] = useState(products);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const productsPerPage = 102;

  useEffect(() => {
    if (products) {
      setProductCount(products.length);
      setProductList(products);
    }
  }, [products, setProductCount]);

  useEffect(() => {
    // Load favorites from session storage
    const favoritesString = sessionStorage.getItem("favorites") || "";
    const favoritesArray = favoritesString.split(',').filter(Boolean).map(Number);
    setFavorites(favoritesArray);
  }, []);

  const applyFilters = useCallback(() => {
    if (searchTerm || activeAlphabet) {
      const filtered = productList
        .filter((product) => {
          if (activeAlphabet) {
            return (
              product.description.charAt(0).toUpperCase() === activeAlphabet
            );
          }
          return true;
        })
        .filter((product) =>
          product.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(productList);
    }
  }, [searchTerm, activeAlphabet, productList]);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, activeAlphabet, productList, applyFilters]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const modalId = `#productModal-${product.productId}`;
    const modalElement = document.querySelector(modalId);
    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    }
  };

  const handleFavoriteClick = (product, isFavorite) => {
    setProductList((prevList) =>
      prevList.map((p) =>
        p.productId === product.productId ? { ...p, isFavorite } : p
      )
    );
    setFavorites((prevFavorites) => {
      let newFavorites;
      if (isFavorite) {
        newFavorites = [...prevFavorites, product.productId];
      } else {
        newFavorites = prevFavorites.filter(
          (id) => id !== product.productId
        );
      }
      // Update session storage
      sessionStorage.setItem("favorites", newFavorites.join(','));
      return newFavorites;
    });
  };

  const handleWishlistClick = (pluCode) => {
    setSelectedPluCode(pluCode);
  };

  // Calculate pagination
  const totalPages = Math.max(
    1,
    Math.ceil(filteredProducts.length / productsPerPage)
  );
  const safeCurrentPage = Math.min(Math.max(1, currentPage), totalPages);

  const currentProducts = filteredProducts.slice(
    (safeCurrentPage - 1) * productsPerPage,
    safeCurrentPage * productsPerPage
  );

  const handleLocalResetClick = () => {
    handleResetClick();
    applyFilters();
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <section className="products-list pt-0">
      <div className="container">
        <div className="row text-center">
          <p className="search-items">
            {searchTerm || activeAlphabet ? (
              <>
                You have <strong>{filteredProducts.length}</strong> results from
                your search result.
              </>
            ) : (
              <>
                You have total <strong>{productList.length}</strong> results in
                your Products.
              </>
            )}
          </p>
        </div>
        <div className="row pt-2">
          <div className="d-flex justify-content-center pb-4">
            <button
              type="button"
              className="btn-two btn-add btn-save"
              onClick={handleLocalResetClick}
            >
              Reset to all Items
            </button>
          </div>
          {currentProducts.length > 0 ? (
            currentProducts.map((product) => (
              <React.Fragment key={product.productId}>
                <ProductCard
                  product={product}
                  onProductClick={handleProductClick}
                  onFavoriteClick={handleFavoriteClick}
                  onWishlistClick={handleWishlistClick}
                  isFavorite={favorites.includes(product.productId)}
                />
                <ProductModal
                  product={product}
                  onFavoriteClick={handleFavoriteClick}
                  onWishlistClick={handleWishlistClick}
                />
              </React.Fragment>
            ))
          ) : (
            <div className="col-12 text-center">
              <p>
                No products found on this page. Try changing filters or going to
                a different page.
              </p>
            </div>
          )}
        </div>
        <Pagination
          currentPage={safeCurrentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <WishlistModal pluCode={selectedPluCode} />
      <ScrollToTop />
    </section>
  );
};

export default ProductList;