import React, { useState, useEffect } from "react";
import { LuLeaf } from "react-icons/lu";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { GoTag } from "react-icons/go";
import defaultImage from "../assets/images/noImage.jpg";
import axios from "axios";
import imgLoader from "../assets/images/imgLoader.png";
import { Modal } from "bootstrap";

const CheckCircleFillIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
  </svg>
);

const ProductCard = ({
  product,
  onProductClick,
  onFavoriteClick,
  onWishlistClick,
  isFavorite: initialIsFavorite,
}) => {
  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setIsFavorite(initialIsFavorite);
  }, [initialIsFavorite]);

  const imageUrl =
    product.imageName === "/images/no-image-available.png"
      ? defaultImage
      : product.imageName;

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    try {
      const updatedFavoriteStatus = !isFavorite;
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/MakeFavorite/MakeFavorite",
        [
          {
            IsDeleteFlag: !updatedFavoriteStatus,
            ProductId: product.productId,
            UserNumber: "13479",
          },
        ],
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

      // Update the favorite status in the component state
      setIsFavorite(updatedFavoriteStatus);

      // Call the onFavoriteClick prop
      onFavoriteClick(product, updatedFavoriteStatus);

      // Show alert when product is added to favorites
      if (updatedFavoriteStatus) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1800);
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleWishlistClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Open the wishlist modal
    const wishlistModal = new Modal(document.getElementById("wishlistModal"));
    wishlistModal.show();

    // Call the onWishlistClick prop
    onWishlistClick(product.pluCode);
  };

  return (
    <>
      <CheckCircleFillIcon />
      <div
        className={`col-sm-6 col-md-4 ${
          !product.isActive ? "inactive-product" : ""
        }`}
      >
        <div className="m-card">
          <div className="m-card-img">
            <img
              src={imageLoaded ? imageUrl : imgLoader}
              className="img-fluids"
              alt={product.description}
              onClick={() => onProductClick(product)}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(false)}
            />
            <div className="m-card-img-cart">
              <LuLeaf />
              <PiShoppingCartSimpleBold />
            </div>
          </div>
          <div className="m-card-content">
            <h3 className="m-card-content-product-code">
              {product.pluCode}{" "}
              <span className="product-name">{product.description}</span>
            </h3>
            <div className="m-card-content-product-adds">
              <GoTag className="card-icons1" onClick={handleWishlistClick} />
              <i
                className="fa-solid fa-heart"
                style={{
                  color: isFavorite ? "yellow" : "gray",
                  cursor: "pointer",
                }}
                onClick={handleFavoriteClick}
              ></i>
            </div>
          </div>
          {!product.isActive && (
            <div className="product-inactive">
              <h2>Inactive</h2>
            </div>
          )}
        </div>
        {showAlert && (
          <div
            className="alert alert-success d-flex align-items-center justify-content-center position-fixed start-50 translate-middle-x mobileAlert"
            role="alert"
            style={{
              top: "5rem",
              zIndex: 1000,
              gap: "10px",
              animation: "fadeInOut 1.5s ease-in-out backwards",
              opacity: 0,
            }}
          >
            <svg
              className="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Success:"
            >
              <use xlinkHref="#check-circle-fill" />
            </svg>
            <div className="alertParah">
              Product added to favorites successfully! 💛
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductCard;