import React, { useState, useEffect } from "react";
import ProductList from "../components/ProductList";
import { useNavigate, useLocation } from "react-router-dom";
import VoiceSearch from "../components/VoiceSearch";
import { HiMiniXMark } from "react-icons/hi2";
import { CiFilter } from "react-icons/ci";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useAllProducts from "../hooks/useAllProducts";

const ProductListSearch = () => {
  const [productCount, setProductCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeAlphabet, setActiveAlphabet] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isListening, setIsListening] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { products, loading, error } = useAllProducts();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterAttributes, setFilterAttributes] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get("search");
    if (search) {
      setSearchTerm(search);
    }

    // Load saved filters from localStorage
    const savedFilters =
      JSON.parse(localStorage.getItem("productFilters")) || {};
    const { departments, attributes, filter } = savedFilters;

    setSelectedDepartments(departments || []);
    setSelectedAttributes(attributes || []);
    setSelectedFilter(filter || null);

    // Apply saved filters
    if (products) {
      updateFilteredProducts(
        departments || [],
        attributes || [],
        filter || null
      );
    }
  }, [location, products]);

  useEffect(() => {
    if (products) {
      setFilteredProducts(products);
    }
  }, [products]);

  const saveFiltersToLocalStorage = (departments, attributes, filter) => {
    const filters = { departments, attributes, filter };
    localStorage.setItem("productFilters", JSON.stringify(filters));
  };

  const handleAlphabetClick = (letter) => {
    setActiveAlphabet(letter);
  };

  const handleResetClick = () => {
    setActiveAlphabet("");
    setSelectedDepartments([]);
    setSelectedAttributes([]);
    setSelectedFilter(null);
    setFilterAttributes([]);
    setFilteredProducts(products);

    // Clear localStorage
    localStorage.removeItem("productFilters");
  };

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
     
      return;
    }

    if (isListening) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isListening]);

  useEffect(() => {
    if (transcript && !isListening) {
      const encodedTranscript = encodeURIComponent(transcript);
      navigate(`/products?search=${encodedTranscript}`);
      resetTranscript();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [transcript, isListening, navigate, resetTranscript]);

  const handleMicClick = () => {
    if (!animationActive) {
      setAnimationActive(true);
      setTimeout(() => {
        setAnimationActive(false);
      }, 1300);
    }
    setIsListening(!isListening);
  };

  const toggleFilter = () => {
    setIsFilterActive(!isFilterActive);
  };

  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
    saveFiltersToLocalStorage(selectedDepartments, selectedAttributes, filter);
    if (filter === "inSeason" || filter === "isActive") {
      setFilterAttributes(["true", "false"]);
    } else {
      const attributes = [
        ...new Set(products.map((product) => product[filter]).filter(Boolean)),
      ];
      setFilterAttributes(attributes);
    }
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === "department") {
      setSelectedDepartments((prev) => {
        const newSelected = prev.includes(value)
          ? prev.filter((d) => d !== value)
          : [...prev, value];
        saveFiltersToLocalStorage(
          newSelected,
          selectedAttributes,
          selectedFilter
        );
        updateFilteredProducts(newSelected, selectedAttributes, selectedFilter);
        return newSelected;
      });
    } else if (filterType === "attribute") {
      setSelectedAttributes((prev) => {
        const newSelected = prev.includes(value)
          ? prev.filter((a) => a !== value)
          : [...prev, value];
        saveFiltersToLocalStorage(
          selectedDepartments,
          newSelected,
          selectedFilter
        );
        updateFilteredProducts(
          selectedDepartments,
          newSelected,
          selectedFilter
        );
        return newSelected;
      });
    }
  };

  const updateFilteredProducts = (departments, attributes, filter) => {
    const newFilteredProducts = products.filter(
      (product) =>
        (departments.length === 0 ||
          departments.includes(product.departmentName)) &&
        (attributes.length === 0 ||
          (filter && attributes.includes(String(product[filter]))))
    );
    setFilteredProducts(newFilteredProducts);
  };

  const getFilterDisplayName = (filter) => {
    switch (filter) {
      case "inSeason":
        return "In Season";
      case "isActive":
        return "Product Status";
      default:
        return filter.charAt(0).toUpperCase() + filter.slice(1);
    }
  };

  const getAttributeDisplayText = (attribute, filter) => {
    if (filter === "inSeason") {
      return attribute === "true" ? "Yes" : "No";
    } else if (filter === "isActive") {
      return attribute === "true" ? "Active" : "Inactive";
    }
    return attribute;
  };

  return (
    <section className="product-list-search">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="subheading text-center">
              Products <span>({productCount})</span>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span
                className="left-pan"
                onClick={() => {
                  setSearchTerm("");
                  navigate("/products");
                }}
              >
                <HiMiniXMark
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </span>
            </div>
          </div>
          {/* ... (rest of the component remains unchanged) ... */}

          <div className="col-md-12 d-flex justify-content-between m-mega-filter-box">
            <div className="btns mb-4">
              <button className="btn-one" onClick={() => navigate("/wishlist")}>
                Wishlist
              </button>
              <button
                className="btn-one"
                onClick={() => navigate("/favorites")}
              >
                Favorite
              </button>
              <button
                className="btn-one"
                onClick={() => navigate("/products/searchhistory")}
              >
                History
              </button>
            </div>
            <button
              className={`btn-three btn-filter ${
                isFilterActive ? "active" : ""
              } d-flex align-items-center`}
              onClick={toggleFilter}
            >
              <CiFilter className="text-center" style={{ fontSize: "28px" }} />{" "}
              Filter
            </button>
            <div className={`mega-filter ${isFilterActive ? "show" : ""}`}>
              <div className="filter-cat">
                <h5>Department</h5>
                <div className="filter-content filter-dept">
                  {["Fruit", "Vegetable", "Nuts", "Bakery", "Misc"].map(
                    (dept) => (
                      <label key={dept}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={selectedDepartments.includes(dept)}
                          onChange={() =>
                            handleFilterChange("department", dept)
                          }
                        />
                        {dept}
                      </label>
                    )
                  )}
                </div>
              </div>
              <div className="filter-cat">
                <h5>Filter</h5>
                <div className="filter-content filter-type">
                  {[
                    "category",
                    "geography",
                    "aliases",
                    "color",
                    "variety",
                    "packaging",
                    "size",
                    "unitOfMeasure",
                    "inSeason",
                    "isActive",
                  ].map((filter) => (
                    <a
                      href=" "
                      className={`btn btn-filter-label ${
                        selectedFilter === filter ? "active" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleFilterSelection(filter);
                      }}
                      key={filter}
                    >
                      {getFilterDisplayName(filter)}
                    </a>
                  ))}
                </div>
              </div>
              <div className="filter-cat">
                <h5>Attributes</h5>
                <div className="filter-content filter-type-attribute">
                  {filterAttributes.map((attribute) => (
                    <a
                      href=" "
                      className={`btn btn-filter-label ${
                        selectedAttributes.includes(attribute) ? "active" : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleFilterChange("attribute", attribute);
                      }}
                      key={attribute}
                    >
                      {getAttributeDisplayText(attribute, selectedFilter)}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {(selectedDepartments.length > 0 ||
            selectedAttributes.length > 0) && (
            <div className="col-md-12 product-list-search-results d-md-flex justify-content-md-start align-content-md-center">
              <p className="m-0">Showing Results for</p>
              <div className="product-list-search-results-suggestion">
                {selectedDepartments.map((dept) => (
                  <span key={dept}>
                    <i
                      className="fa-solid fa-xmark"
                      style={{ color: "#5F5F5F", cursor: "pointer" }}
                      onClick={() => handleFilterChange("department", dept)}
                    ></i>
                    {dept}
                  </span>
                ))}
                {selectedAttributes.map((attr) => (
                  <span key={attr}>
                    <i
                      className="fa-solid fa-xmark"
                      style={{ color: "#5F5F5F", cursor: "pointer" }}
                      onClick={() => handleFilterChange("attribute", attr)}
                    ></i>
                    {getAttributeDisplayText(attr, selectedFilter)}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className="col-md-12 filter-letters">
            {[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
            ].map((letter) => (
              <p
                className={`alphabet text-center ${
                  letter === activeAlphabet ? "active" : ""
                }`}
                key={letter}
                onClick={() => handleAlphabetClick(letter)}
              >
                {letter}
              </p>
            ))}
          </div>
        </div>
      </div>

      <ProductList
        setProductCount={setProductCount}
        searchTerm={searchTerm}
        activeAlphabet={activeAlphabet}
        handleResetClick={handleResetClick}
        products={filteredProducts}
      />
      <VoiceSearch />
    </section>
  );
};

export default ProductListSearch;
