// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mikeBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.mic {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  background-color: #92920b;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.25s;
}

.mic::before,
.mic::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-color: inherit;
  z-index: -1;
}

.listening {
  background-color: #ffff0f;
  z-index: 100;
}

.listening::before {
  animation: listening 0.8s infinite;
}

.listening::after {
  animation: listening 1s infinite 0.2s; /* Delay of 0.2s */
}

@keyframes listening {
  from {
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/utility/Mic.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kCAAkC;AACpC;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,qCAAqC,EAAE,kBAAkB;AAC3D;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".mikeBox {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 25px;\n}\n\n.mic {\n  position: relative;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  width: 180px;\n  height: 180px;\n  background-color: #92920b;\n  border: none;\n  border-radius: 50%;\n  cursor: pointer;\n  transition: background-color 0.25s;\n}\n\n.mic::before,\n.mic::after {\n  content: \"\";\n  position: absolute;\n  width: inherit;\n  height: inherit;\n  border-radius: inherit;\n  background-color: inherit;\n  z-index: -1;\n}\n\n.listening {\n  background-color: #ffff0f;\n  z-index: 100;\n}\n\n.listening::before {\n  animation: listening 0.8s infinite;\n}\n\n.listening::after {\n  animation: listening 1s infinite 0.2s; /* Delay of 0.2s */\n}\n\n@keyframes listening {\n  from {\n    opacity: 1;\n  }\n  to {\n    transform: scale(2);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
