import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../utility/Loader";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import micIcon from "../../assets/images/mic.png";

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/Departments/Departments",
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );

        if (response.data && response.data.departments) {
          setDepartments(response.data.departments);
        } else {
          setDepartments([]);
        }

        setLoading(false);
      } catch (error) {
        setError("Failed to fetch departments");
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="product-department">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HiOutlineArrowLongLeft
              style={{
                fontSize: "60px",
                color: "#1C1C1C",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/home")}
            />
            <h1
              className="subheading text-center"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "17px",
              }}
            >
              Departments <span className="span">({departments.length})</span>
              <img src={micIcon} alt="" className="micIcon" />
            </h1>
          </div>
        </div>

        <div className="row">
          <ul className="product-department-list">
            {departments.map((department) => (
              <li
                key={department.departmentID}
                onClick={() =>
                  navigate(
                    `/departments/subdepartment/${department.departmentID}`,
                    { state: { departmentName: department.departmentName } }
                  )
                }
                className="dLi"
              >
                <p className="anc">{department.departmentName}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Departments;
