import { useState } from 'react';
import axios from 'axios';

const useClearFavorites = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const clearFavorites = async (customerId) => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + '/api/Favorite/ClearFavorites',
        {
          customeR_ID: customerId,
        },
        {
          headers: {
            'LoginToken': '8A5FC4A0-351B-4ED8-99DF-500933B005F4'
          },
        }
      );
      console.log('Request Data:', { customeR_ID: customerId });
      console.log('Response Data:', res.data);
      setResponse(res.data);
    } catch (err) {
     
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { clearFavorites, loading, error, response };
};

export default useClearFavorites;
