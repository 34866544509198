import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../utility/Loader';
import logo from '../../assets/images/Logo.png';



const OtpInput = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://localhost:7088/api/VerifyOtp', {
        email: location.state.email,
        otp
      });

      if (response.data.success) {
        navigate('/reset-pin', { state: { email: location.state.email } });
      } else {
        setError('Invalid OTP');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login-form p-0">
      <div className="container-fluid p-lg-0 p-md-0">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wrap d-md-flex">
            <div className="img d-flex align-items-center justify-content-center">
                <img className="logo-img" src={logo} alt="Logo" />
              </div>
              <div className="login-wrap">
                <div className="form-title w-100 d-flex align-items-center justify-content-lg-between">
                  <h2 className="authheading">Enter OTP</h2>
                </div>
                <p className="form-sub-heading">
                  Please enter the OTP sent to your email
                </p>
                <form onSubmit={handleSubmit} className="m-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="form-footer d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn-two" disabled={loading}>
                      {loading ? <Loader /> : 'Submit'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpInput;




