// Login.js
import React, { useState, useRef, useEffect } from "react";
import logo from "../../assets/images/Logo.png";
import { useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import Loader from "../../utility/Loader";
import { FiInfo } from "react-icons/fi";

const Login = () => {
  const [UserName, setUserName] = useState("");
  const [Pin, setPin] = useState("");
  const { login, loading, error } = useLogin();
  const navigate = useNavigate();
  const userNameRef = useRef(null);

  useEffect(() => {
    if (userNameRef.current) {
      userNameRef.current.focus();
    }
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    await login(UserName, Pin);
  };

  const handleForgotPin = (event) => {
    event.preventDefault();
    navigate("/forgot-pin");
  };

  return (
    <section className="login-form p-0">
      <div className="container-fluid p-lg-0 p-md-0">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wrap d-md-flex">
              <div className="img d-flex align-items-center justify-content-center">
                <img className="logo-img" src={logo} alt="Logo" />
              </div>
              <div className="login-wrap">
                <div className="form-title w-100 d-flex align-items-center justify-content-between justify-content-lg-between">
                  <h2 className="authheading">Welcome</h2>
                  <span
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <FiInfo className="fs-3" style={{ color: "D4BB2A" }} />
                    <span className="mt-1">Help</span>
                  </span>
                </div>
                <p className="form-sub-heading">
                  Please sign in to get started
                </p>
                <form onSubmit={handleLogin} className="m-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User ID"
                      value={UserName}
                      onChange={(e) => setUserName(e.target.value)}
                      ref={userNameRef}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter your pin"
                      value={Pin}
                      onChange={(e) => setPin(e.target.value)}
                      required
                    />
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="form-footer d-flex justify-content-between align-items-center">
                    <button
                      type="submit"
                      className="btn-two"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Login"}
                    </button>
                    <p className="text-center m-note">
                      <a href="#forgot-pin" onClick={handleForgotPin}>
                        Forgot pin?
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
