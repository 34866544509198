import React from "react";

const WishlistLimitModal = ({ errorMessage }) => {
  return (
    <div
      className="modal fade"
      id="wishlistLimitModal"
      tabIndex="-1"
      aria-labelledby="wishlistLimitModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content text-center">
          <div className="modal-header">
            <p className="modal-title " id="wishlistLimitModalLabel">
              {errorMessage === "User cannot create more than 5 wishlists"
                ? "Wishlist Limit Reached!"
                : "Error"}
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{errorMessage}</div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-warning px-5 rounded-4"
              data-bs-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistLimitModal;
