import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FavoritesGrid from "../components/FavoritesGrid";
import axios from "axios";
import Loader from "../utility/Loader";
import { HiMiniXMark } from "react-icons/hi2";

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Favorite/GetFavorites",
          { customeR_ID: 13479 }, 
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );

        if (response.data && response.data.userFavoriteProductInfo) {
          setFavorites(response.data.userFavoriteProductInfo);
        } else {
          setFavorites([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, []);

  const handleFavoriteUpdate = (updatedProducts) => {
    setFavorites(updatedProducts);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <section className="product-list-search">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="subheading text-center">
              Favorite <span>({favorites.length})</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
              />
              <span className="left-pan">
                <HiMiniXMark
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-between m-mega-filter-box">
            <div className="btns me-auto">
              <button className="btn-one" onClick={() => navigate("/wishlist")}>
                Wishlist
              </button>
              <button className="btn-one active-btn">Favorite</button>
              <button
                className="btn-one"
                onClick={() => navigate("/products/searchhistory")}
              >
                History
              </button>
            </div>
          </div>
        </div>
        <FavoritesGrid
          products={favorites}
          onFavoriteUpdate={handleFavoriteUpdate}
        />
      </div>
    </section>
  );
};

export default Favorites;
