import React from "react";

function LanguageModal() {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog language-popup">
        <div className="modal-contents" style={{ width: "100%" }}>
          <div className="modal-header border-0 p-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            <h4 className="modal-title" id="exampleModalLabel">
              Choose Language
            </h4>
            <ul className="choose-language">
              <li>
                <a href=" " className="active">
                  English
                </a>
              </li>
              <li>
                <a href=" " className="a">
                  Spanish
                </a>
              </li>
              <li>
                <a href=" ">Arabic</a>
              </li>
              <li>
                <a href=" ">Polish</a>
              </li>
            </ul>

            <div className="d-flex justify-content-center">
              <button type="button" className="btn-two">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageModal;
