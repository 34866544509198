import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { HiOutlineArrowLongRight, HiArrowLongLeft } from "react-icons/hi2";
import { useParams, useNavigate } from "react-router-dom";
import "./plustyles.css";
import Loader from "../../utility/Loader";

const MultipleChoice = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const alertRef = useRef(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const requestBody = { projectID: parseInt(projectId, 10) };

        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/GetProjectTestQuestions/GetProjectTestQuestions",
          requestBody,
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.errorMessage?.errorCode === 1) {
          setQuestions(response.data.projectQuestions);
          setLoading(false);
        } else if (response.data.errorMessage?.errorCode === -1) {
          navigate("/project/pluError");
        } else {
          console.error(
            "Failed to fetch questions:",
            response.data.errorMessage
          );
          setError(response.data.errorMessage.errorDetails);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [projectId, navigate]);

  useEffect(() => {
    if (alertMessage && alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollBy(0, -window.innerHeight * 1.4);
      const timeout = setTimeout(() => {
        setAlertMessage("");
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [alertMessage]);

  const handleOptionChange = (questionIndex, option) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: option,
    });
  };

  const handleSubmit = () => {
    // Check if all questions have been answered
    const allAnswered = questions.every(
      (question, index) => selectedAnswers[index] !== undefined
    );

    if (!allAnswered) {
      setAlertMessage("You need to select options from every question!");
      return;
    }

    // Set submitted to true to indicate that the user has submitted their answers
    setSubmitted(true);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  const groupedQuestions = [];
  for (let i = 0; i < questions.length; i += 2) {
    groupedQuestions.push(questions.slice(i, i + 2));
  }

  return (
    <>
      <section className="qa-list">
        <div className="container">
          <div className="row text-center">
            <h2 className="subheading mb-0">My PLU tests</h2>
            <p className="sub-heading pb-0">
              Code test <span className="pluSpan">Multiple choice</span>
            </p>
          </div>
          {error && (
            <div className="row">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            </div>
          )}
          {alertMessage && (
            <div className="row">
              <div className="col" ref={alertRef}>
                <div className="alert alert-warning" role="alert">
                  {alertMessage}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            {groupedQuestions.map((questionPair, groupIndex) => (
              <div
                className="col-sm-12 col-md-10 mx-autos"
                key={groupIndex}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "55px",
                }}
              >
                {questionPair.map((question, index) => (
                  <div className="qa-list-code-test-list" key={index}>
                    <h3 className="h3css">{question.question}</h3>
                    <form>
                      {question.options.split("^").map((option, i) => {
                        const isCorrect = option === question.answer;
                        const isSelected =
                          option === selectedAnswers[groupIndex * 2 + index];
                        const labelClass =
                          submitted && isCorrect
                            ? "form-check-label green-check"
                            : submitted && isSelected && !isCorrect
                            ? "form-check-label red-check"
                            : "form-check-label";

                        return (
                          <div className="form-check" key={i}>
                            <input
                              type="radio"
                              className="form-check-input"
                              id={`btn${groupIndex * 2 + index}-${i}`}
                              name={`optradio${groupIndex * 2 + index}`}
                              onChange={() =>
                                handleOptionChange(
                                  groupIndex * 2 + index,
                                  option
                                )
                              }
                              checked={isSelected}
                              disabled={submitted}
                            />
                            <label
                              className={labelClass}
                              htmlFor={`btn${groupIndex * 2 + index}-${i}`}
                            >
                              {option}{" "}
                              {submitted && isCorrect ? (
                                <i className="fa fa-check text-success"></i>
                              ) : submitted && isSelected && !isCorrect ? (
                                <i className="fa fa-times text-danger"></i>
                              ) : null}
                            </label>
                          </div>
                        );
                      })}
                    </form>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="row">
            <nav aria-label="Page navigation inner-page-navigation">
              <ul className="pagination inner-page-navigation justify-content-center border-0">
                <li
                  className="d-flex gap-2 align-items-center justify-content-center"
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                >
                  <HiArrowLongLeft style={{ fontSize: "30px" }} />
                  <p className="paraCustom">Prev</p>
                </li>
                <li
                  className="submit active d-flex gap-2 align-items-center justify-content-center"
                  onClick={handleSubmit}
                  style={{ cursor: "pointer" }}
                >
                  <p className="paraCustom">Next</p>
                  <HiOutlineArrowLongRight style={{ fontSize: "30px" }} />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default MultipleChoice;
