import React from "react";

const CorrectIncorrect = () => {
  return (
    <section className="qa-list">
      <div className="container">
        <div className="row text-center">
          <h2>My PLU tests</h2>
          <p className="sub-heading pb-0">
            Code test <span>Multiple choice</span>
          </p>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8 mx-auto qa-list-code-test">
            <div className="qa-list-code-test-list">
              <h3>4011 is the code for</h3>
              <form>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn1"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn1">
                    Gala Apple
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn2"
                    name="optradio"
                  />
                  <label
                    className="form-check-label green-check"
                    htmlFor="btn2"
                  >
                    Banana <i className="fa fa-check text-success"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn3"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn3">
                    Broccoli
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn4"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn4">
                    Broccoli
                  </label>
                </div>
              </form>
            </div>
            <div className="qa-list-code-test-list">
              <h3>1843 is the code for</h3>
              <form>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn5"
                    name="optradio"
                  />
                  <label className="form-check-label red-check" htmlFor="btn5">
                    Plum Tomato <i className="fa fa-times text-danger"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn6"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn6">
                    Red Seedless Grapes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn7"
                    name="optradio"
                  />
                  <label
                    className="form-check-label green-check"
                    htmlFor="btn7"
                  >
                    Leek <i className="fa fa-check text-success"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn8"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn8">
                    Honeycrisp Apple
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-8 mx-auto qa-list-code-test">
            <div className="qa-list-code-test-list">
              <h3>2651 is the code for</h3>
              <form>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn9"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn9">
                    Avocado
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn10"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn10">
                    White Peach
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn11"
                    name="optradio"
                  />
                  <label
                    className="form-check-label green-check"
                    htmlFor="btn11"
                  >
                    Black Cherries <i className="fa fa-check text-success"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn12"
                    name="optradio"
                  />
                  <label className="form-check-label red-check" htmlFor="btn12">
                    Butter Lettuce <i className="fa fa-times text-danger"></i>
                  </label>
                </div>
              </form>
            </div>
            <div className="qa-list-code-test-list">
              <h3>4672 is the code for</h3>
              <form>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn13"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn13">
                    Nectarine
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn14"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn14">
                    Navel Orange
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn15"
                    name="optradio"
                  />
                  <label
                    className="form-check-label green-check"
                    htmlFor="btn15"
                  >
                    Red Pepper <i className="fa fa-check text-success"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="btn16"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="btn16">
                    Corn
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <nav aria-label="Page navigation inner-page-navigation">
            <ul className="pagination inner-page-navigation justify-content-center border-0">
              <li className="prev">
                <a href=" ">Prev</a>
              </li>
              <li className="submit active">
                <a href=" ">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default CorrectIncorrect;
