import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apples from "../../assets/images/qa/apples.jpg";
import { HiArrowLongLeft } from "react-icons/hi2";


const Identification = () => {
  return (
    <section className="qa-list products-list-four">
      <div className="container">
        <div className="row text-center">
          <h2 className="subheading mb-0">My PLU tests</h2>
          <p className="sub-heading pb-0">
            Code test <span className="pluSpan">Identification</span>
          </p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="m-card">
              <div className="m-card-img">
                <img
                  src={apples}
                  className="img-fluids"
                  alt="Grannysmith Apple"
                />
              </div>
              <div className="m-card-qa">
                <h3 className="m-card-qa-title">Grannysmith Apple</h3>
                <div className="m-card-qa-content">
                  <input type="text" placeholder="3981" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6">
            <div className="m-card">
              <div className="m-card-img">
                <img
                  src={apples}
                  className="img-fluids"
                  alt="Grannysmith Apple"
                />
              </div>
              <div className="m-card-qa">
                <h3 className="m-card-qa-title">Grannysmith Apple</h3>
                <div className="m-card-qa-content">
                  <input type="text" placeholder="3981" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <nav aria-label="Page navigation inner-page-navigation">
            <ul className="pagination inner-page-navigation justify-content-center border-0">
              <li
                className="d-flex gap-2 align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <HiArrowLongLeft style={{ fontSize: "30px" }} />
                <p className="paraCustom">Prev</p>
              </li>
              <li
                className="submit active d-flex gap-2 align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <p className="paraCustom">Submit</p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Identification;
