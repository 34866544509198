import React, { useState } from 'react';
import logo from '../../assets/images/Logo.png';
import { useNavigate } from 'react-router-dom';
import useForgotPin from '../../hooks/useForgotPin';
import Loader from '../../utility/Loader';

const ForgotPin = () => {
  const [email, setEmail] = useState('');
  const { forgotPin, loading, error, success } = useForgotPin();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await forgotPin(email);
  };

  const handleLoginRedirect = (event) => {
    event.preventDefault();
    navigate('/');
  };

  return (
    <section className="login-form p-0">
      <div className="container-fluid p-lg-0 p-md-0">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wrap d-md-flex">
              <div className="img d-flex align-items-center justify-content-center">
                <img className="logo-img" src={logo} alt="Logo" />
              </div>
              <div className="login-wrap">
                <div className="form-title w-100 d-flex align-items-center justify-content-lg-between">
                  <h2 className="authheading">Forgot pin</h2>
                  <span>
                    <i className="fa-light fa-circle-info"></i> Help
                  </span>
                </div>
                <p className="form-sub-heading">
                  Please enter the following details to retrieve your pin
                </p>
                <form onSubmit={handleSubmit} className="m-form">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  {success && <div className="alert alert-success">Check your email for further instructions</div>}
                  <div className="form-footer d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn-two" disabled={loading}>
                      {loading ? <Loader /> : 'Submit'}
                    </button>
                    <p className="text-center m-note">
                      <a href=" " onClick={handleLoginRedirect}>Login</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPin;
