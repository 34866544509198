import React, { useState, useEffect } from "react";
import axios from "axios";

const WishlistModal = ({ pluCode }) => {
  const [wishlistName, setWishlistName] = useState("");
  const [wishlists, setWishlists] = useState([]);
  const [selectedWishlist, setSelectedWishlist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savingLoading, setSavingLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  useEffect(() => {
    fetchWishlistsData();
  }, []);

  const fetchWishlistsData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/Wishlist/GetManageWishList",
        { userId: 13479 },
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );
      if (response.data.errorCode === 0) {
        setWishlists(response.data.wishLists || []);
      } else {
        setError("Error fetching wishlists: " + response.data.errorDetails);
      }
    } catch (error) {
      setError("Error fetching wishlists: " + error.message);
    }
  };

  const handleInputChange = (e) => {
    setWishlistName(e.target.value);
  };

  const handleCreateClick = async () => {
    if (wishlistName.trim() !== "" && wishlists.length < 5) {
      setLoading(true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Wishlist/ManageWishList",
          {
            Action: "CREATE",
            Description: "Description",
            Name: wishlistName,
            UserId: "13479",
            WishlistID: "0",
          },
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );
        if (response.data.errorCode === 1) {
          await fetchWishlistsData();
          setWishlistName("");
        } else {
          setError("Error creating wishlist: " + response.data.errorDetails);
        }
      } catch (error) {
        setError("Error creating wishlist: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRadioChange = (id) => {
    setSelectedWishlist(id);
  };

  const handleSaveClick = async () => {
    if (selectedWishlist) {
      setSavingLoading(true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Wishlist/ManageWishListItem",
          [
            {
              Action: "CREATE",
              ProductID: pluCode.toString(),
              UserID: "13479",
              WishlistID: selectedWishlist.toString(),
              WishlistItemID: "0",
            },
          ],
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );
        if (response.data.errorCode === 1) {
          setConfirmationMessage("Product added successfully!");
          setTimeout(() => {
            setConfirmationMessage("");
          }, 1500);
        } else {
          setError(
            "Error adding product to wishlist: " + response.data.errorDetails
          );
        }
      } catch (error) {
        setError("Error adding product to wishlist: " + error.message);
      } finally {
        setSavingLoading(false);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="wishlistModal"
      tabIndex="-1"
      aria-labelledby="wishlistModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog new-wishlist">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="modal-title" id="wishlistModalLabel">
              Create new wishlist
            </h4>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="col-md-12">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
                value={wishlistName}
                onChange={handleInputChange}
              />
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn-two btn-add"
                  onClick={handleCreateClick}
                  disabled={loading}
                >
                  {loading ? "Creating..." : "Create"}
                </button>
              </div>
              <div className="wishlist-selected-wrp pt-4">
                <form>
                  {wishlists.map((wishlist) => (
                    <div className="wishlist-options" key={wishlist.wishlistID}>
                      <input
                        type="radio"
                        name="radio-group"
                        id={`radio-${wishlist.wishlistID}`}
                        checked={selectedWishlist === wishlist.wishlistID}
                        onChange={() => handleRadioChange(wishlist.wishlistID)}
                      />
                      <label
                        htmlFor={`radio-${wishlist.wishlistID}`}
                        className="form-control-wishlist"
                      >
                        <div className="d-flex align-items-center gap-2">
                          <p className="wishlistname">{wishlist.name}</p>
                        </div>
                      </label>
                    </div>
                  ))}
                  <div className="d-flex justify-content-center mt-4">
                    {confirmationMessage ? (
                      <div
                        className="alert alert-success mb-0"
                        style={{ padding: "12px 31px", borderRadius: "25px", fontSize: "1rem", fontWeight: "500" }}
                      >
                        {confirmationMessage}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn-two btn-add btn-save"
                        onClick={handleSaveClick}
                        disabled={savingLoading || !selectedWishlist}
                      >
                        {savingLoading ? "Saving..." : "Save"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistModal;
