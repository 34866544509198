import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./app/pages/Auth/Login";
import ForgotPin from "./app/pages/Auth/ForgotPin";
import ResetPin from "./app/pages/Auth/ResetPin";
import ChangePin from "./app/pages/Auth/ChangePin";
import Setting from "./app/components/Setting";
import Home from "./app/pages/Dashboard/Home";
import MainLayout from "./app/pages/MainLayout";
import Wishlist from "./app/pages/Wishlist";
import OtpInput from "./app/pages/Auth/OtpInput";
import ProtectedRoute from "./app/ProtectedRoute";
import Products from "./app/pages/Products";
import Departments from "./app/pages/Departments/Departments";
import Notifications from "./app/pages/Notifications";
import SubDepartment from "./app/pages/Departments/SubDepartment";
import Favorites from "./app/pages/Favorites";
import SearchHistory from "./app/pages/SearchHistory";
import SubDepartItems from "./app/pages/Departments/SubDepartItems";
import SubItemsPage from "./app/pages/Departments/SubItemspage";
import WeeklySalesPage from "./app/pages/WeeklySalesPage";
import PLUtest from "./app/pages/PLU/PLUtest";
import SingleWishlist from "./app/pages/SingleWishlist";
import MultipleChoice from "./app/pages/PLU/MultipleChoice";
import PluError from "./app/pages/PLU/PluError";
import CorrectIncorrect from "./app/pages/PLU/CorrectIncorrect";
import Identification from "./app/pages/PLU/Identification";
import Result from "./app/pages/PLU/Result";
import ScorePage from "./app/pages/PLU/ScorePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-pin" element={<ForgotPin />} />
        <Route path="/settings/reset-pin" element={<ResetPin />} />
        <Route path="/change-pin" element={<ChangePin />} />
        <Route exact path="/verify-otp" element={<OtpInput />} />

        {/* Protected routes wrapped in MainLayout */}
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/settings" element={<Setting />} />
          <Route path="/home" element={<Home />} />
          <Route path="/wishlist" element={<Wishlist />} />

          <Route
            path="/wishlist/singlewishlist/:wishlistID"
            element={<SingleWishlist />}
          />
          <Route path="/weeklysales" element={<WeeklySalesPage />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/plutest" element={<PLUtest />} />
          <Route
            path="/plutest/project/:projectId"
            element={<MultipleChoice />}
          />
          <Route
            path="/project/:projectId/result"
            element={<CorrectIncorrect />}
          />
          <Route path="/project/pluError" element={<PluError />} />
          <Route path="/identification" element={<Identification />} />
          <Route path="/plutest/:projectID/result" element={<Result />} />
          <Route path="/plutest/:projectID/result/score-page" element={<ScorePage />} />


          <Route path="/products" element={<Products />} />

          {/* departments route group */}
          <Route path="/departments" element={<Departments />} />
          <Route
            path="/departments/subdepartment/:departmentID"
            element={<SubDepartment />}
          />
          <Route
            path="/departments/subdepartment/items/:subDepartmentID"
            element={<SubItemsPage />}
          />

          <Route
            path="/departments/subdepartment/items/:subDepartmentID"
            element={<SubDepartItems />}
          />

          <Route path="/products/searchhistory" element={<SearchHistory />} />

          <Route path="/notifications" element={<Notifications />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
