import React from "react";
import { useNavigate } from "react-router-dom";
import { IoWarning } from "react-icons/io5";

const PluError = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-5"
        style={{ minHeight: "50vh" }}
      >
        <div className="d-flex gap-2">
          <h1>Error</h1>
          <IoWarning style={{ fontSize: "45px", color: "#ffc107" }} />
        </div>

        <p className="text-center" style={{ fontWeight: "400" }}>
          Index was outside the bounds of the array.
        </p>
        <div>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate("/plutest")}
          >
            Go Back to Projects
          </button>
        </div>
      </div>
    </div>
  );
};

export default PluError;
