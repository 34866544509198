import React from "react";
import { useLocation } from "react-router-dom";
import "./Loader.css";

const Loader = () => {
  const location = useLocation();
  const isLoginPath = location.pathname === "/" || location.pathname === "/wishlist" || location.pathname === "/settings/reset-pin";

  return (
    <div className="loader-container">
      <svg viewBox="25 25 50 50" className="loader">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
      {!isLoginPath && <p>Loading...</p>}
    </div>
  );
};

export default Loader;
