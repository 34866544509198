import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import arrowIcon from '../assets/images/uparrow.png';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > window.innerHeight * 1.3) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 600,
      smooth: true,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div onClick={scrollToTop}>
          <img src={arrowIcon} alt="Go to top" />
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
