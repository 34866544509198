const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

const generateKey = async () => {
  return await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  );
};

const encryptData = async (data, key) => {
  const encodedData = textEncoder.encode(data);
  const iv = crypto.getRandomValues(new Uint8Array(12)); 
  const encryptedData = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    key,
    encodedData
  );

  return {
    iv: Array.from(iv),
    encryptedData: Array.from(new Uint8Array(encryptedData)),
  };
};

const decryptData = async (encryptedData, iv, key) => {
  const decryptedData = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: new Uint8Array(iv),
    },
    key,
    new Uint8Array(encryptedData)
  );

  return textDecoder.decode(decryptedData);
};

const importKey = async (rawKey) => {
  return await crypto.subtle.importKey(
    'raw',
    rawKey,
    'AES-GCM',
    true,
    ['encrypt', 'decrypt']
  );
};

export { generateKey, encryptData, decryptData, importKey };