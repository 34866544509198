import React, { useEffect, useState } from "react";
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoiceSearch from "../components/VoiceSearch";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AddModal from "../components/AddModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SearchHistory = () => {
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [isRemoving, setIsRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedSearchWords = localStorage.getItem("searchWords");
    if (storedSearchWords) {
      setSearchList(storedSearchWords.split(","));
    }
  }, []);

  const handleClearAll = async () => {
    if (searchList.length === 0) return;

    setLoading(true);
    setError(null);

    const lastKeyword = searchList[searchList.length - 1];
    const bodyData = [
      {
        IsDeleteFlag: "true",
        Search: lastKeyword,
        SearchType: "false",
        UserNumber: "13479",
      },
    ];

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/SaveSearches/SaveSearches",
        bodyData,
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

      if (response.data.errorMessage.errorCode === 1) {
        // Clear from local storage and state
        localStorage.removeItem("searchWords");
        setSearchList([]);
      } else {
        setError("Failed to clear search history from backend.");
      }
    } catch (error) {
      setError("Error clearing search history: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItem = async (indexToRemove) => {
    setIsRemoving(true);
    const wordToRemove = searchList[indexToRemove];

    const bodyData = [
      {
        IsDeleteFlag: "true",
        Search: wordToRemove,
        SearchType: "false",
        UserNumber: "13479",
      },
    ];

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/SaveSearches/SaveSearches",
        bodyData,
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

      if (response.data.errorMessage.errorCode === 1) {
        const updatedList = searchList.filter((_, i) => i !== indexToRemove);
        setSearchList(updatedList);
        localStorage.setItem("searchWords", updatedList.join(","));
      } else {
        setError("Failed to remove search word from backend.");
      }
    } catch (error) {
      setError("Error removing search word: " + error.message);
    } finally {
      setIsRemoving(false);
    }
  };

  const handleSearch = (term) => {
    navigate(`/products?search=${encodeURIComponent(term)}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(inputSearch);
    }
  };

  const handleKeywordAdded = (newKeyword) => {
    const updatedList = [...searchList, newKeyword];
    setSearchList(updatedList);
    localStorage.setItem("searchWords", updatedList.join(","));
  };

  return (
    <section className="product-list-search">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="subheading text-center">Search history</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <span className="left-pan">
                <i
                  className="fa fa-thin fa-xmark"
                  style={{ color: "black", cursor: "pointer" }}
                  onClick={() => setInputSearch("")}
                ></i>
              </span>
            </div>
          </div>
          <div className="col-md-12 d-flex">
            <div className="btns me-auto">
              <button className="btn-one" onClick={() => navigate("/wishlist")}>
                Wishlist
              </button>
              <button
                className="btn-one"
                onClick={() => navigate("/favorites")}
              >
                Favorite
              </button>
              <button className="btn-one active-btn">History</button>
            </div>
            <div className="btns">
              <button
                type="button"
                className="btn-two btn-add"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                <FontAwesomeIcon icon={faPlus} size="xl" /> Add
              </button>
              <button
                className="btn-clear"
                onClick={handleClearAll}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faXmark} size="xl" />
                {loading ? "Clearing..." : "Clear all"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="pt-10 product-result">
        <div className="container">
          <div className="row ">
            {searchList.length > 0 ? (
              searchList.map((word, index) => (
                <div
                  key={index}
                  className="col-md-2 product-voice-search-result"
                >
                  <div
                    className="wordsearch mt-3"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSearch(word);
                    }}
                  >
                    <p className="keyword-p" style={{border: "none"}}>{word}</p>
                    <i
                      className="fa-solid fa-xmark black"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveItem(index);
                      }}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-sm-12 col-md-12 text-center">
                <p className="search-history">No search history found</p>
              </div>
            )}
            <div className="col-sm-12 col-md-12 text-center">
              <VoiceSearch />
            </div>
          </div>
        </div>
      </section>
      <AddModal onKeywordAdded={handleKeywordAdded} />
    </section>
  );
};

export default SearchHistory;
