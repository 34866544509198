import React, { useState } from "react";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SubDepartmentItems from "./SubDepartItems";
import micIcon from "../../assets/images/mic.png";

const SubItemsPage = () => {
  const navigate = useNavigate();
  const { subDepartmentID } = useParams();
  const location = useLocation();
  const { subDepartmentName } = location.state || {};
  const [itemCount, setItemCount] = useState(0);
  const [activeAlphabet, setActiveAlphabet] = useState("");

  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleAlphabetClick = (letter) => {
    setActiveAlphabet(letter);
  };

  const handleResetClick = () => {
    setActiveAlphabet("");
  };

  return (
    <section className="product-list-search">
      <div className="container">
        {/* Heading */}
        <div className="row">
          <div className="col-md-12">
            <HiOutlineArrowLongLeft
              style={{
                fontSize: "60px",
                color: "#1C1C1C",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
            <h1 className="subheading text-center">
              {subDepartmentName} <span>({itemCount})</span>
              <img src={micIcon} alt="" className="micIcon" />
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
              />
              <span className="left-pan">
                <i
                  className="fa fa-thin fa-xmark"
                  style={{ color: "black" }}
                ></i>
              </span>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-between m-mega-filter-box">
            <div className="btns me-auto">
              <button className="btn-one" onClick={() => navigate("/wishlist")}>
                Wishlist
              </button>
              <button
                className="btn-one"
                onClick={() => navigate("/favorites")}
              >
                Favorite
              </button>
              <button
                className="btn-one"
                onClick={() => navigate("/products/searchhistory")}
              >
                History
              </button>
            </div>
            <button className="btn-three btn-filter ms-auto">
              <i className="fa fa-thin fa-filter"></i> Filter
            </button>
            <div className="mega-filter">
              <div className="filter-cat">
                <h5>Department</h5>
                <div className="filter-content filter-dept">
                  <label>
                    <input type="checkbox" className="form-check-input" /> Fruit
                  </label>
                  <label>
                    <input type="checkbox" className="form-check-input" />{" "}
                    Vegetables
                  </label>
                  <label>
                    <input type="checkbox" className="form-check-input" /> Nuts
                  </label>
                  <label>
                    <input type="checkbox" className="form-check-input" />{" "}
                    Bakery
                  </label>
                  <label>
                    <input type="checkbox" className="form-check-input" /> Misc
                  </label>
                </div>
              </div>
              <div className="filter-cat">
                <h5>Filter</h5>
                <div className="filter-content filter-type">
                  {[
                    "Category",
                    "Packaging",
                    "Best Seller",
                    "Color",
                    "Measurement",
                    "Wholesale",
                    "Status",
                    "Geography",
                    "Organic",
                  ].map((label, index) => (
                    <a
                      href=" "
                      className={`btn btn-filter-label ${
                        index % 8 === 0 ? "active" : ""
                      }`}
                      key={index}
                    >
                      {label}
                    </a>
                  ))}
                </div>
              </div>
              <div className="filter-cat">
                <h5>Attributes</h5>
                <div className="filter-content filter-type-attribute">
                  {[
                    "Asian",
                    "Coast Tropical",
                    "Indian",
                    "Mexican",
                    "Australian",
                    "Florida",
                    "Italian",
                    "Michigan",
                    "California",
                  ].map((label, index) => (
                    <a
                      href=" "
                      className={`btn btn-filter-label ${
                        index % 4 === 2 ? "active" : ""
                      }`}
                      key={index}
                    >
                      {label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 filter-letters">
            {alphabets.map((letter) => (
              <p
                className={`alphabet ${
                  letter === activeAlphabet ? "active" : ""
                }`}
                href=" "
                key={letter}
                onClick={() => handleAlphabetClick(letter)}
              >
                {letter}
              </p>
            ))}
          </div>
        </div>
        {/* This is where the Items Grid will be rendered */}
        <SubDepartmentItems
          subDepartmentID={subDepartmentID}
          setItemCount={setItemCount}
          activeAlphabet={activeAlphabet}
          handleResetClick={handleResetClick} // Pass handleResetClick
        />
      </div>
    </section>
  );
};

export default SubItemsPage;
