import React, { useState, useEffect } from "react";
import axios from "axios";
import { LuLeaf } from "react-icons/lu";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { GoTag, GoHeart } from "react-icons/go";
import Pagination from "../../components/Pagination";
import Loader from "../../utility/Loader";
import defaultImage from "../../assets/images/noImage.jpg";
import imgLoader from "../../assets/images/imgLoader.png";
import ScrollToTop from "../../components/ScrollTotop";

const ItemsCard = ({ product, onProductClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageUrl =
    product.imageName === "/images/no-image-available.png"
      ? defaultImage
      : product.imageName;

  return (
    <div
      className={`col-sm-6 col-md-4 ${
        !product.isActive ? "inactive-product" : ""
      }`}
    >
      <div className="m-card" onClick={() => onProductClick(product)}>
        <div className="m-card-img">
          <img
            src={imageLoaded ? imageUrl : imgLoader}
            className="img-fluids"
            alt={product.description}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageLoaded(false)}
          />
          <div className="m-card-img-cart">
            <LuLeaf />
            <PiShoppingCartSimpleBold />
          </div>
        </div>
        <div className="m-card-content">
          <h3 className="m-card-content-product-code">
            {product.pluCode}{" "}
            <span className="product-name">{product.description}</span>
          </h3>
          <div className="m-card-content-product-adds">
            <GoTag />
            <GoHeart />
          </div>
        </div>
        {!product.isActive && (
          <div className="product-inactive">
            <h2>Inactive</h2>
          </div>
        )}
      </div>
    </div>
  );
};

const SubDepartmentItems = ({
  subDepartmentID,
  setItemCount,
  activeAlphabet,
  handleResetClick,
}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL +
            "/api/ProductBySubDepartment/ProductBySubDepartment",
          {
            departmentID: parseInt(subDepartmentID),
          },
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.products) {
          setProducts(response.data.products);
          setItemCount(response.data.products.length);
        } else {
          console.error(
            "Response data does not contain products array",
            response
          );
          setError("Response data does not contain products array");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products");
        setLoading(false);
      }
    };

    fetchProducts();
  }, [subDepartmentID, setItemCount]);

  const filteredProducts = activeAlphabet
    ? products.filter((product) =>
        product.description.toUpperCase().startsWith(activeAlphabet)
      )
    : products;

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
        }}
      >
        <Loader />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <section className="products-list pt-0">
      <div className="container">
        <div className="row text-center">
          <p className="search-items">
            You have <strong>{filteredProducts.length}</strong> results from
            your search
          </p>
          <div className="d-flex justify-content-center pb-4">
            <button
              type="button"
              className="btn-two btn-add btn-save"
              onClick={handleResetClick}
            >
              Reset to all Items
            </button>
          </div>
        </div>
        <div className="row">
          {currentProducts.map((product) => (
            <React.Fragment key={product.productId}>
              <ItemsCard product={product} onProductClick={() => {}} />
            </React.Fragment>
          ))}
        </div>
        {filteredProducts.length > productsPerPage && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
        <ScrollToTop />
      </div>
    </section>
  );
};

export default SubDepartmentItems;
