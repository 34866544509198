import { useState, useEffect } from "react";
import axios from "axios";

const useAllProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Products/GetAllProducts",
          {},
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );
        setProducts(response.data.productDetails);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return { products, loading, error };
};

export default useAllProducts;
