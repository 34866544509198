import { useState, useEffect } from 'react';
import axios from 'axios';

const useWishlist = () => {
  const [wishlists, setWishlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWishlists = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/Wishlist/GetManageWishList',
        { userId: 13479 },
        {
          headers: {
            LoginToken: '8A5FC4A0-351B-4ED8-99DF-500933B005F4',
          },
        }
      );
      if (response.data && response.data.wishLists) {
        setWishlists(response.data.wishLists);
      }
    } catch (error) {
     
      setError('Failed to fetch wishlists. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const deleteWishlist = async (wishlistToDelete) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + '/api/Wishlist/ManageWishList',
        {
          Action: 'DELETE',
          Description: wishlistToDelete.description,
          Name: wishlistToDelete.name,
          UserId: '13479',
          WishlistID: wishlistToDelete.wishlistID.toString(),
        },
        {
          headers: {
            LoginToken: '8A5FC4A0-351B-4ED8-99DF-500933B005F4',
          },
        }
      );

      // Update local state by filtering out the deleted wishlist
      const updatedWishlists = wishlists.filter(wishlist => wishlist.wishlistID !== wishlistToDelete.wishlistID);
      setWishlists(updatedWishlists);
    } catch (error) {
      
      setError('Failed to delete wishlist. Please try again.');
    }
  };

  const createWishlist = async (name, description = 'Description') => {
    if (wishlists.length >= 5) {
      setError('User cannot create more than 5 wishlists');
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/Wishlist/ManageWishList',
        {
          Action: 'CREATE',
          Description: description,
          Name: name,
          UserId: '13479',
          WishlistID: '0',
        },
        {
          headers: {
            LoginToken: '8A5FC4A0-351B-4ED8-99DF-500933B005F4',
          },
        }
      );

      if (response.data.errorCode === 1) {
        fetchWishlists(); 
      } else {
        setError(response.data.errorDetails || 'Failed to create wishlist. Please try again.');
      }
    } catch (error) {
   
      setError('Failed to create wishlist. Please try again.');
    }
  };


  const deleteWishlistItem = async (wishlistID, wishlistItemID, productID) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/Wishlist/ManageWishListItem',
        [
          {
            Action: 'DELETE',
            ProductID: productID.toString(),
            UserID: '13479',
            WishlistID: wishlistID.toString(),
            WishlistItemID: wishlistItemID.toString(),
          }
        ],
        {
          headers: {
            LoginToken: '8A5FC4A0-351B-4ED8-99DF-500933B005F4',
          },
        }
      );
      if (response.data.errorCode === 1) {
        return true;
  
      } else {
     
        return false;
      }
    } catch (error) {
     
      return false;
    }
  };

  const getWishlistItemCount = async (wishlistID) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/Wishlist/GetManageWishListItem',
        { wishID: wishlistID },
        {
          headers: {
            LoginToken: '8A5FC4A0-351B-4ED8-99DF-500933B005F4',
          },
        }
      );
      if (response.data && response.data.wishListItems) {
        return response.data.wishListItems.length;
      }
      return 0;
    } catch (error) {
    
      return 0;
    }
  };

  useEffect(() => {
    fetchWishlists();
  }, []);

  return { wishlists, loading, error, deleteWishlist, createWishlist, fetchWishlists, getWishlistItemCount, deleteWishlistItem };

};

export default useWishlist;
