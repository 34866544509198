import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";

import WeeklySales from "../../components/WeeklySales";
import TopSellers from "../../components/TopSellers";
import micImage from "../../assets/images/mic.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../utility/Mic.css";
import VoiceSearch from "../../components/VoiceSearch";
import HomeDepartments from "./HomeDepartments";

const Home = () => {
  const [isListening, setIsListening] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return;
    }

    if (isListening) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isListening]);

  useEffect(() => {
    if (transcript && !isListening) {
      handleSearch(transcript);
      resetTranscript();
    }
  }, [transcript, isListening, resetTranscript]);

  const handleMicClick = () => {
    if (!animationActive) {
      setAnimationActive(true);
      setTimeout(() => {
        setAnimationActive(false);
      }, 1300);
    }
    setIsListening(!isListening);
  };

  const handleSearch = async (term) => {
    const userToken = "8A5FC4A0-351B-4ED8-99DF-500933B005F4"; // Static LoginToken
    const staticUserNumber = "13479"; // Static UserNumber

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/SaveSearches/SaveSearches",
        [
          {
            IsDeleteFlag: "false",
            Search: term,
            SearchType: "false",
            UserNumber: staticUserNumber,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
            LoginToken: userToken,
          },
        }
      );

      if (response.data.errorMessage.errorCode === 1) {
        console.log("Search term saved successfully");

        // Update localStorage
        const currentSearchWords = localStorage.getItem("searchWords") || "";
        const updatedSearchWords = currentSearchWords
          ? `${currentSearchWords},${term}`
          : term;
        localStorage.setItem("searchWords", updatedSearchWords);
      } else {
      }
    } catch (err) {
      console.error(
        "Error saving search term:",
        err.response ? err.response.data : err.message
      );
    }

    navigate(`/products?search=${encodeURIComponent(term)}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchTerm);
    }
  };

  return (
    <div>
      <header className="header-bg">
        <div className="row justify-content-center find-plu">
          <div className="col-8">
            <h1 className="heading">FIND YOUR PLU</h1>
            <input
              type="text"
              placeholder="Enter Keyword"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="col-2">
            <button
              type="button"
              className={`mic ${isListening ? "listening" : ""} ${
                animationActive ? "animate" : ""
              }`}
              onClick={handleMicClick}
            >
              <img src={micImage} alt="Mic" className="text-center " />
            </button>
          </div>
        </div>
      </header>
      <HomeDepartments />
      <WeeklySales />
      <TopSellers />
      <VoiceSearch />
    </div>
  );
};

export default Home;
