import React from "react";

const DeleteWishlistModal = ({ wishlistToDelete, handleDeleteConfirm }) => {
  return (
    <div
      className="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <p className="modal-body confirmtext text-center" id="deleteModalLabel">
            Are you sure you want to delete the wishlist?
          </p>

          <div className="modal-footer d-flex justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-outline-warning px-5 rounded-4"
              data-bs-dismiss="modal"
              onClick={() => handleDeleteConfirm(wishlistToDelete)}
              style={{ fontSize: "18px" }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secondary px-5 rounded-4"
              data-bs-dismiss="modal"
              style={{ fontSize: "18px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWishlistModal;
