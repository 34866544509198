import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWishlist from "../hooks/useWishlist";
import Loader from "../utility/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteWishlistModal from "../components/DeleteWishlistModal";
import WishlistLimitModal from "../components/WishlistLimitModal";
import { Modal } from "bootstrap";
import { HiMiniXMark } from "react-icons/hi2";

const Wishlist = () => {
  const navigate = useNavigate();
  const {
    wishlists,
    loading,
    deleteWishlist,
    createWishlist,
    fetchWishlists,
    error,
    getWishlistItemCount,
  } = useWishlist();
  const [newWishlistName, setNewWishlistName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingWishlistId, setLoadingWishlistId] = useState(null);
  const [creatingWishlist, setCreatingWishlist] = useState(false);
  const [wishlistItemsCount, setWishlistItemsCount] = useState({});
  const [wishlistToDelete, setWishlistToDelete] = useState(null);

  useEffect(() => {
    fetchWishlists();
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      const modalElement = document.getElementById("wishlistLimitModal");
      let modalInstance = Modal.getInstance(modalElement);
      if (!modalInstance) {
        modalInstance = new Modal(modalElement);
      }
      modalInstance.show();
      modalElement.addEventListener(
        "hidden.bs.modal",
        () => {
          setErrorMessage(null);
        },
        { once: true }
      );
    }
  }, [error]);

  const handleAddWishlist = async () => {
    if (newWishlistName.trim() !== "") {
      setCreatingWishlist(true);
      setErrorMessage(null); // Reset error before creating
      await createWishlist(newWishlistName);
      setNewWishlistName("");
      setCreatingWishlist(false);
    }
  };

  const handleDeleteWishlist = async (wishlist) => {
    setLoadingWishlistId(wishlist.wishlistID);
    await deleteWishlist(wishlist);
    setLoadingWishlistId(null);
  };

  const handleDeleteConfirm = (wishlist) => {
    handleDeleteWishlist(wishlist);
  };

  const loadWishlistItemsCount = async () => {
    const counts = {};
    for (const wishlist of wishlists) {
      const count = await getWishlistItemCount(wishlist.wishlistID);
      counts[wishlist.wishlistID] = count;
    }
    setWishlistItemsCount(counts);
  };

  useEffect(() => {
    loadWishlistItemsCount();
  }, [wishlists]);

  const handleNavigateToSingleWishlist = (wishlistID, wishlistName) => {
    navigate(`/wishlist/singlewishlist/${wishlistID}`, {
      state: { wishlistName },
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <section className="product-list-search">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="subheading text-center">
                Wishlist <span>({wishlists.length})</span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <input
                  type="text"
                  className="search-product"
                  placeholder="Enter keyword"
                />
                <span className="left-pan d-flex gap-3 align-items-center">
                  <HiMiniXMark
                    style={{
                      color: "black",
                      cursor: "pointer",
                      fontSize: "35px",
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-between m-mega-filter-box">
              <div className="btns me-auto">
                <button className="btn-one active-btn">Wishlist</button>
                <button
                  className="btn-one"
                  onClick={() => navigate("/favorites")}
                >
                  Favorite
                </button>
                <button
                  className="btn-one"
                  onClick={() => navigate("/products/searchhistory")}
                >
                  History
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-wishlistpage pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="m-card">
                <h4 className="modal-title d-flex justify-items-start">
                  Create new Wishlist
                </h4>
                <form className="m-form" action="#">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control search-wishlist"
                      placeholder="Enter Wishlist"
                      value={newWishlistName}
                      onChange={(e) => setNewWishlistName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn-two btn-add"
                      onClick={handleAddWishlist}
                    >
                      {creatingWishlist ? <Loader /> : "Create"}
                    </button>
                  </div>
                </form>
              </div>

              <div className="wishlist-selected-items">
                <ul className="wishlistul">
                  {wishlists.map((wishlist) => (
                    <li key={wishlist.wishlistID} style={{ cursor: "pointer" }}>
                      <label
                        onClick={() =>
                          handleNavigateToSingleWishlist(
                            wishlist.wishlistID,
                            wishlist.name
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {wishlist.name}{" "}
                        <span className="customSpan">
                          ({wishlistItemsCount[wishlist.wishlistID]})
                        </span>
                      </label>
                      {loadingWishlistId === wishlist.wishlistID ? (
                        <Loader />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="xl"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                          onClick={() => setWishlistToDelete(wishlist)}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteWishlistModal
        wishlistToDelete={wishlistToDelete}
        handleDeleteConfirm={handleDeleteConfirm}
      />

      <WishlistLimitModal errorMessage={errorMessage} />
    </>
  );
};

export default Wishlist;
