import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../utility/Loader";
import "../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

const Result = () => {
  const { projectID } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const loginToken = "8A5FC4A0-351B-4ED8-99DF-500933B005F4";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL +
            "/api/GetEmployeeProjects/GetEmployeeProjects",
          { employeeID: 13479 },
          {
            headers: {
              LoginToken: loginToken,
              "Content-Type": "application/json",
            },
          }
        );

        const projectData = response.data.projects.find(
          (p) => p.projectID === parseInt(projectID, 10)
        );
        setProject(projectData);
      } catch (error) {
        console.error("Error fetching project:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectID, loginToken]);

  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = callback;
      script.onerror = () => console.error(`Script load error for ${src}`);
      document.body.appendChild(script);
    };

    const loadCircularProgressScripts = (callback) => {
      loadScript(
        "https://rawgit.com/kottenator/jquery-circle-progress/1.2.2/dist/circle-progress.js",
        callback
      );
      loadScript("https://code.jquery.com/jquery-3.6.0.min.js", callback);
    };

    if (!window.jQuery || !window.jQuery.fn.circleProgress) {
      loadCircularProgressScripts(() => {
        if (project) {
          const circleDiv = document.querySelector(".circle2");
          if (circleDiv) {
            circleDiv.setAttribute("data-percent", project.testResult);
            if (window.jQuery) {
              window.jQuery(circleDiv).circleProgress({
                startAngle: -Math.PI / 2,
                value: project.testResult / 100,
                size: 88,
                thickness: 2,
                fill: {
                  color: "#e6e600",
                },
              });
            } else {
              console.error("jQuery or CircleProgress script not loaded");
            }
          }
        }
      });
    } else {
      if (project) {
        const circleDiv = document.querySelector(".circle2");
        if (circleDiv) {
          circleDiv.setAttribute("data-percent", project.testResult);
          window.jQuery(circleDiv).circleProgress({
            startAngle: -Math.PI / 2,
            value: project.testResult / 100,
            thickness: 5,
            fill: {
              color: "#e6e600",
            },
          });
        }
      }
    }
  }, [project]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <p>Error: {error}</p>;
      </div>
    );
  }

  return (
    <section className="qa-list plu-text-listing-table">
      <div className="container">
        <HiOutlineArrowLongLeft
          style={{
            fontSize: "60px",
            color: "#1C1C1C",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
        <div className="d-flex align-items-center justify-content-center gap-3">
          <h2 className="text-start subheading mb-0">PLU Test:</h2>
          <span className="customSpan1">{project?.projectName}</span>
        </div>

        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "3rem" }}
        >
          <div className="results">
            <div className="progressbar">
              <div className="circle2" data-percent={project.testResult}>
                <strong>{project.testResult}%</strong>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "3rem" }}
        >
          <button
            type="button"
            className="btn btn-warning px-5 py-2 rounded-4"
            style={{ fontSize: "25px" }}
            onClick={() => navigate(`/plutest/${projectID}/result/score-page`)}
          >
            See results
          </button>
        </div>
      </div>
    </section>
  );
};

export default Result;