import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePLU from "../../hooks/usePLU";
import Loader from "../../utility/Loader";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

const ScorePage = () => {
  const navigate = useNavigate();
  const { projectID } = useParams();
  const employeeID = 13479; // This should be stored in a more secure way
  const loginToken = "8A5FC4A0-351B-4ED8-99DF-500933B005F4"; // This should be stored in a more secure way
  const { data, loading, error } = usePLU(employeeID, loginToken);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const project = data?.find((p) => p.projectID === parseInt(projectID, 10));

  if (!project) {
    return <p>Project not found</p>;
  }

  const {
    projectName,
    testResult,
    completedDate,
    name,
    correct,
    incorrect,
    total,
  } = project;

  return (
    <section className="qa-list plu-text-listing-table">
      <div className="container d-flex flex-column justify-content-center">
        <HiOutlineArrowLongLeft
          style={{
            fontSize: "60px",
            color: "#1C1C1C",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
        {/* Project details section */}
        <div className="projectLine">
          <h2 className="text-start projecthead mb-0">PLU Test:</h2>
          <span className="customSpan1">{projectName}</span>
        </div>
        <div className="nameDiv px-2">
          <h1 className="paraCustom2 mb-0">{name}</h1>
          <div className="d-flex align-items-center gap-1">
            <p className="customSpan2 mb-0">{completedDate}</p>
          </div>
        </div>

        {/* pass/fail status */}
        <div className="testResult">
          <p
            className="subheading2 mb-0"
            style={{ color: parseInt(testResult) >= 35 ? "Green" : "red" }}
          >
            {parseInt(testResult) >= 35 ? "Passed" : "Failed"}
          </p>{" "}
          <p className="paraCustom2 mb-0">Score: {testResult}%</p>
        </div>

        {/* Question stats */}
        <div
          className="d-flex flex-column align-items-center gap-2"
          style={{ marginTop: "4rem" }}
        >
          <div
            className="customSpan2 mb-0 py-3 px-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #d0cdcd",
              width: "100%",
            }}
          >
            Number of Questions: <p className="paraCustom2 mb-0">{total}</p>
          </div>
          <div
            className="customSpan2 mb-0 py-3 px-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #d0cdcd",
              width: "100%",
            }}
          >
            Correct Answers: <p className="paraCustom2 mb-0">{correct}</p>
          </div>
          <div
            className="customSpan2 mb-0 py-3 px-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #d0cdcd",
              width: "100%",
            }}
          >
            Wrong Answers: <p className="paraCustom2 mb-0">{incorrect}</p>
          </div>
        </div>

        {/* Close button */}
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "3rem" }}
        >
          <button
            type="button"
            className="btn-two"
            style={{ fontSize: "25px" }}
            onClick={() => navigate("/plutest")}
          >
            Close
          </button>
        </div>
      </div>
    </section>
  );
};

export default ScorePage;
