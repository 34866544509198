import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import micImage from "../assets/images/mic.png";
import "../utility/Mic.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const VoiceSearch = () => {
  const [isListening, setIsListening] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isFinal,
  } = useSpeechRecognition();
  const navigate = useNavigate();

  useEffect(() => {
    if (isFinal && transcript) {
      // Delay the navigation slightly to ensure the full transcript is captured
      setTimeout(() => {
        navigate(`/products?search=${transcript}`);
        resetTranscript();
      }, 900);
    }
  }, [isFinal, transcript, navigate, resetTranscript]);

  const handleMicClick = () => {
    if (!animationActive) {
      setAnimationActive(true);
      setTimeout(() => {
        setAnimationActive(false);
      }, 1000);
    }
    if (isListening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({ continuous: true });
    }
    setIsListening(!isListening);
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <footer className="pt-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mikeBox">
            <h2 className="subheading text-center pb-3">Voice search</h2>
            <div className="mikeBox">
              <button
                type="button"
                className={`mic ${isListening ? "listening" : ""} ${
                  animationActive ? "animate" : ""
                }`}
                onClick={handleMicClick}
              >
                <img src={micImage} alt="" className="text-center" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default VoiceSearch;
