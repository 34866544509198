import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  generateKey,
  encryptData,
  decryptData,
  importKey,
} from "../utility/Crypto";

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cryptoKey, setCryptoKey] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchKey = async () => {
      let key;
      const storedKey = sessionStorage.getItem("cryptoKey");
      if (storedKey) {
        key = await importKey(new Uint8Array(JSON.parse(storedKey)));
      } else {
        key = await generateKey();
        const exportedKey = await crypto.subtle.exportKey("raw", key);
        sessionStorage.setItem(
          "cryptoKey",
          JSON.stringify(Array.from(new Uint8Array(exportedKey)))
        );
      }
      setCryptoKey(key);
    };
    fetchKey();
  }, []);

  const login = async (UserName, Pin) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/Login/LoginUser",
        {
          Pin,
          UserName,
        }
      );

      if (response.data.errorMessage.errorCode === 1) {
        const encryptedUserName = await encryptData(
          response.data.username,
          cryptoKey
        );
        const encryptedUserNumber = await encryptData(
          response.data.userNumber,
          cryptoKey
        );
        const encryptedFirstName = await encryptData(
          response.data.firstName,
          cryptoKey
        );

        localStorage.setItem("searchWords", response.data.searchWords || "");
        sessionStorage.setItem("food-user", response.data.userToken);
        sessionStorage.setItem("userName", JSON.stringify(encryptedUserName));
        sessionStorage.setItem(
          "userNumber",
          JSON.stringify(encryptedUserNumber)
        );
        sessionStorage.setItem("firstName", JSON.stringify(encryptedFirstName));

        // Store favorites in session storage
        sessionStorage.setItem("favorites", response.data.favourites || "");

        navigate("/home");
      } else {
        setError(
          response.data.errorMessage.errorDetails || "Invalid credentials"
        );
      }
    } catch (err) {
      if (err.response) {
        setError(
          err.response.data.errorMessage.errorDetails || "Invalid credentials"
        );
      } else if (err.request) {
        setError("Network error. Please try again.");
      } else {
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

 

  return { login, loading, error, decryptData };
};

export default useLogin;