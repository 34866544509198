import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import usePLU from "../../hooks/usePLU";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../utility/Loader";

const PLUtest = () => {
  const employeeID = 13479;
  const loginToken = "8A5FC4A0-351B-4ED8-99DF-500933B005F4";
  const navigate = useNavigate();

  const { data, loading, error } = usePLU(employeeID, loginToken);

  useEffect(() => {
    if (data) {
      data.forEach((project, index) => {
        const circleDiv = document.querySelector(
          `.circle[data-index="${index}"]`
        );
        if (circleDiv) {
          circleDiv.setAttribute("data-percent", project.testResult);
          if (window.CircularProgress) {
            window.CircularProgress.update(circleDiv);
          } else {
            console.error("CircularProgress script not loaded");
          }
        }
      });
    }
  }, [data]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const handleProjectClick = (project) => {
    if (project.testResult === "0" || project.testResult === "") {
      navigate(`/plutest/project/${project.projectID}`);
    } else {
      navigate(`/plutest/${project.projectID}/result`);
    }
  };

  return (
    <section className="qa-list plu-text-listing-table">
      <div className="container">
        <div className="row text-center">
          <h2 className="text-center subheading">My PLU tests</h2>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table" id="sortTable">
              <thead className="thead">
                <tr className="">
                  <th className="">SL No</th>
                  <th className="">Test name</th>
                  <th className="">Date</th>
                  <th className="">Status</th>
                  <th className="">% Correct</th>
                </tr>
              </thead>
              <tbody className="">
                {data?.map((project, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td>{index + 1}</td>
                    <td onClick={() => handleProjectClick(project)}>
                      {project.projectName}
                    </td>
                    <td>{project.assignedDate}</td>
                    <td>{project.projectStatus}</td>
                    <td className="results">
                      <div className="progressbar">
                        <div
                          className="circle"
                          data-index={index}
                          data-percent={project.testResult}
                        >
                          <strong></strong>
                        </div>
                      </div>
                      <select className="form-select" aria-label="Default select">
                        <option defaultValue>Result</option>
                        <option value="1">One</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PLUtest;
