import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useWishlist from "../hooks/useWishlist";
import Loader from "../utility/Loader";
import ScrollToTop from "../components/ScrollTotop";
import { HiOutlineArrowLongLeft, HiMiniXMark } from "react-icons/hi2";
import { LuLeaf } from "react-icons/lu";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import { GoTag } from "react-icons/go";
import micIcon from "../assets/images/mic.png";
import defaultProductImage from "../assets/images/noImage.jpg";
import WishlistedProductDeleteModal from "../components/WishlistedProductDeleteModal";
import { Modal } from "bootstrap";

const SingleWishlist = () => {
  const navigate = useNavigate();
  const { wishlistID } = useParams();
  const location = useLocation();
  const { deleteWishlistItem } = useWishlist();

  const [wishlistName, setWishlistName] = useState("");
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [favoriteStatus, setFavoriteStatus] = useState({});

  useEffect(() => {
    if (location.state && location.state.wishlistName) {
      setWishlistName(location.state.wishlistName);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchWishlistItems = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/Wishlist/GetManageWishListItem",
          { wishID: parseInt(wishlistID, 10) },
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
            },
          }
        );
        if (response.data.errorCode === 0) {
          const updatedItems = response.data.wishListItems || [];
          const itemsWithDefaultImage = updatedItems.map((item) => ({
            ...item,
            imageName:
              item.imageName === "/images/no-image-available.png"
                ? defaultProductImage
                : item.imageName,
          }));
          setWishlistItems(itemsWithDefaultImage);

          // Fetch favorite status for each item
          const favoriteStatusResponse = await axios.post(
            process.env.REACT_APP_API_URL + "/api/Favorite/GetFavorites",
            { customeR_ID: 13479 },
            {
              headers: {
                LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
              },
            }
          );

          const favoriteProducts =
            favoriteStatusResponse.data.userFavoriteProductInfo || [];
          const favoriteStatusMap = {};
          favoriteProducts.forEach((product) => {
            favoriteStatusMap[product.PluCode] = true;
          });
          setFavoriteStatus(favoriteStatusMap);
        } else {
          setError("Failed to fetch wishlist items");
        }
      } catch (error) {
        setError("Failed to fetch wishlist items");
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistItems();
  }, [wishlistID]);

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    const modal = new Modal(
      document.getElementById("deleteWishlistProductModal")
    );
    modal.show();
  };

  const handleConfirmDelete = async () => {
    if (!selectedItem) return;

    setLoading(true);
    try {
      const success = await deleteWishlistItem(
        wishlistID,
        selectedItem.wishlistItemID,
        selectedItem.productID
      );

      if (success) {
        setWishlistItems((prevItems) =>
          prevItems.filter(
            (item) => item.wishlistItemID !== selectedItem.wishlistItemID
          )
        );
        setError(null);
      } else {
        setError("Failed to delete the item");
      }
    } catch (error) {
      setError("An error occurred while deleting the item");
    } finally {
      setLoading(false);
      setSelectedItem(null);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "70vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-3">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (wishlistItems.length === 0) {
    return (
      <section className="pt-10 product-result">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 text-center d-flex flex-column gap-4">
              <p className="search-history">
                You have not added any product to{" "}
                <strong style={{ color: "#E5C822" }}>{wishlistName}</strong>{" "}
                wishlist.
              </p>
              <button
                className="btn-clear mx-auto"
                onClick={() => navigate("/products")}
              >
                <HiOutlineArrowLongLeft
                  style={{
                    fontSize: "45px",
                    color: "#D4AC0D",
                    marginRight: "10px",
                  }}
                />
                <p
                  className="text-center m-0"
                  style={{ fontSize: "20px", fontWeight: "normal" }}
                >
                  Go to Products
                </p>
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="product-list-search">
        {/* ... */}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="subheading text-center">
                Wishlist <span>({wishlistItems.length})</span>
                <img src={micIcon} alt="" className="mic-icon" />
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <input
                  type="text"
                  className="search-product"
                  placeholder="Enter keyword"
                />
                <span className="left-pan">
                  <HiMiniXMark
                    style={{
                      color: "black",
                      cursor: "pointer",
                      fontSize: "35px",
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-between m-mega-filter-box">
              <div className="btns me-auto">
                <button
                  className="btn-one active-btn"
                  onClick={() => navigate(-1)}
                >
                  Wishlist
                </button>
                <button
                  className="btn-one"
                  onClick={() => navigate("/favorites")}
                >
                  Favorite
                </button>
                <button
                  className="btn-one"
                  onClick={() => navigate("/products/searchhistory")}
                >
                  History
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="products-list pt-0 products-list-four">
        <div className="container">
          <div className="row">
            <div className="col-md-12 products-category-name">
              <h4 className="pt-4 pb-4">
                {wishlistName} <span>({wishlistItems.length})</span>
              </h4>
            </div>
          </div>
          <div className="row">
            {wishlistItems.map((item) => (
              <div className="col-sm-6 col-md-6" key={item.wishlistItemID}>
                <div className="m-cards">
                  <div className="m-card-img">
                    <img
                      src={item.imageName}
                      className="img-fluids"
                      alt={item.description}
                    />
                    <div className="m-card-img-cart">
                      <LuLeaf />
                      <PiShoppingCartSimpleBold />
                    </div>
                  </div>
                  <div className="m-card-content">
                    <h3 className="m-card-content-product-code">
                      {item.productID}{" "}
                      <span className="product-name">{item.description}</span>
                    </h3>
                    <ul className="m-card-content-product-adds">
                      <GoTag
                        className="card-icons1"
                        onClick={() => handleDeleteClick(item)}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <ScrollToTop />
      <WishlistedProductDeleteModal onConfirm={handleConfirmDelete} />
    </>
  );
};

export default SingleWishlist;
