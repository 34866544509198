import React, { useState, useEffect } from "react";
import { GoTag } from "react-icons/go";
import defaultImage from "../assets/images/noImage.jpg";
import axios from "axios";
import { Modal } from "bootstrap";

const ProductModal = ({ product, onFavoriteClick, onWishlistClick }) => {
  const [isFavorite, setIsFavorite] = useState(product.isFavorite);

  useEffect(() => {
    setIsFavorite(product.isFavorite);
  }, [product.isFavorite]);

  if (!product) return null;

  const imageUrl =
    product.imageName === "/images/no-image-available.png"
      ? defaultImage
      : product.imageName;
  const modalId = `productModal-${product.productId}`;

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    try {
      const updatedFavoriteStatus = !isFavorite;
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/MakeFavorite/MakeFavorite",
        [
          {
            IsDeleteFlag: !updatedFavoriteStatus,
            ProductId: product.productId,
            UserNumber: "13479",
          },
        ],
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );
      setIsFavorite(updatedFavoriteStatus);
      onFavoriteClick(product, updatedFavoriteStatus);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleWishlistClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Close the product modal
    const productModal = Modal.getInstance(document.getElementById(modalId));
    if (productModal) {
      productModal.hide();
    }

    // Open the wishlist modal
    const wishlistModal = new Modal(document.getElementById('wishlistModal'));
    wishlistModal.show();

    // Call the onWishlistClick prop
    onWishlistClick(product.pluCode);
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby="productModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered product-popup modal-lg">
        <div className="modal-contents bg-transparent">
          <div className="modal-header border-0 p-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ fontSize: "25px" }}
            ></button>
          </div>

          <div className="modal-body">
            <div className=".m-card-modal">
              <div className="m-card-img">
                <img
                  src={imageUrl}
                  className="modal-card-img"
                  alt={product.description}
                  style={{
                    borderBottomLeftRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                />
              </div>
              <div className="m-card-content">
                <h3 className="m-card-content-product-code">
                  {product.pluCode}{" "}
                  <span className="product-name">{product.description}</span>
                </h3>
                <div className="m-card-content-product-adds">
                  <GoTag
                    className="card-icons1"
                    onClick={handleWishlistClick}
                  />
                  <i
                    className="fa-solid fa-heart"
                    style={{
                      color: isFavorite ? "yellow" : "gray",
                      cursor: "pointer",
                    }}
                    onClick={handleFavoriteClick}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;