// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}


svg.loader {
    width: 2em;
    height: 2em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
  }
  
  svg.loader circle {
    fill: none;
    stroke: hsl(212, 81%, 6%);
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
  
    100% {
      stroke-dashoffset: -125px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/app/utility/Loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;;AAGA;IACI,UAAU;IACV,WAAW;IACX,wBAAwB;IACxB,qCAAqC;EACvC;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,eAAe;IACf,wBAAwB;IACxB,oBAAoB;IACpB,qBAAqB;IACrB,0CAA0C;EAC5C;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE;MACE,wBAAwB;MACxB,oBAAoB;IACtB;;IAEA;MACE,yBAAyB;MACzB,wBAAwB;IAC1B;;IAEA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loader-container{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n}\n\n\nsvg.loader {\n    width: 2em;\n    height: 2em;\n    transform-origin: center;\n    animation: rotate4 2s linear infinite;\n  }\n  \n  svg.loader circle {\n    fill: none;\n    stroke: hsl(212, 81%, 6%);\n    stroke-width: 2;\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n    stroke-linecap: round;\n    animation: dash4 1.5s ease-in-out infinite;\n  }\n  \n  @keyframes rotate4 {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes dash4 {\n    0% {\n      stroke-dasharray: 1, 200;\n      stroke-dashoffset: 0;\n    }\n  \n    50% {\n      stroke-dasharray: 90, 200;\n      stroke-dashoffset: -35px;\n    }\n  \n    100% {\n      stroke-dashoffset: -125px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
