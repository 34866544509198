import React, { useState } from "react";
import axios from "axios";

const AddModal = ({ onKeywordAdded }) => {
  const [keyword, setKeyword] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [error, setError] = useState(null);

  const handleAddKeyword = async () => {
    if (!keyword.trim()) return;

    setIsAdding(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/SaveSearches/SaveSearches",
        [
          {
            IsDeleteFlag: "false",
            Search: keyword,
            SearchType: "false",
            UserNumber: "13479",
          },
        ],
        {
          headers: {
            LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

      if (response.data.errorMessage.errorCode === 1) {
        onKeywordAdded(keyword);
        setKeyword("");
        setConfirmationMessage("Keyword added successfully!");
        setTimeout(() => {
          setConfirmationMessage("");
        }, 1500);
      } else {
        setError(
          "Error adding keyword: " + response.data.errorMessage.errorDetails
        );
      }
    } catch (error) {
      setError("Error adding keyword: " + error.message);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="addModal"
      tabIndex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog add-popup">
        <div className="keyword-modal">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="modal-title" id="addModalLabel">
              Add new keyword
            </h4>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="col-md-12">
              <input
                type="text"
                className="search-product"
                placeholder="Enter keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="d-flex justify-content-center mt-4">
                {confirmationMessage ? (
                  <div
                    className="alert alert-success mb-0"
                    style={{ padding: "12px 31px", borderRadius: "20px" }}
                  >
                    {confirmationMessage} 👍🏻
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn-two btn-add mx-auto"
                    style={{
                      display: "block",
                      margin: "0 auto",
                    }}
                    onClick={handleAddKeyword}
                    disabled={isAdding}
                  >
                    {isAdding ? "Adding..." : "Add"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
