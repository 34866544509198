import React from 'react';
import logo from "../../assets/images/Logo.png";

const ChangePin = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <section className="login-form p-0">
      <div className="container-fluid p-lg-0 p-md-0">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wrap d-md-flex">
              <div className="img d-flex align-items-center justify-content-center">
                <img className="logo-img" src={logo} alt="Logo" />
              </div>
              <div className="login-wrap">
                <div className="form-title w-100 d-flex align-items-center justify-content-lg-between">
                  <h2 className='authheading'>Change pin</h2>
                  <span><i className="fa-light fa-circle-info"></i> Help</span>
                </div>
                <p className="form-sub-heading">
                  Please provide the following information to change your pin
                </p>
                <form onSubmit={handleSubmit} className="m-form">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Company ID" required />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter User ID" required />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter your pin" required />
                  </div>
                  <div className="form-footer d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn btn-two">Login</button>
                    <p className="text-center m-note"><a href="#signup">Forgot pin?</a></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePin;
