import { useState, useEffect } from 'react';
import axios from 'axios';

const usePLU = (employeeID, loginToken) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = process.env.REACT_APP_API_URL + '/api/GetEmployeeProjects/GetEmployeeProjects';
        const headers = {
          'LoginToken': loginToken,
          'Content-Type': 'application/json'
        };
        const requestBody = { "employeeID": employeeID };
        
        const response = await axios.post(url, requestBody, { headers });
        
       
        
        const projects = response.data.projects; // Ensure this matches the response structure
        setData(projects);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [employeeID, loginToken]);

  return { data, loading, error };
};

export default usePLU;
