import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../utility/Loader";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import micIcon from "../../assets/images/mic.png";

const SubDepartment = () => {
  const { departmentID } = useParams();
  const location = useLocation();
  const [subDepartments, setSubDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { departmentName } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubDepartments = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/SubDepartments/SubDepartments",
          {
            departmentID: parseInt(departmentID),
          },
          {
            headers: {
              LoginToken: "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.subDepartments) {
          setSubDepartments(response.data.subDepartments);
        } else {
          console.error(
            "Response data does not contain subDepartments array",
            response
          );
          setError("Response data does not contain subDepartments array");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching subDepartments:", error);
        setError("Failed to fetch subDepartments");
        setLoading(false);
      }
    };

    fetchSubDepartments();
  }, [departmentID]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="product-department">
      <div className="container">
        <div className="row">
          <div className="product-list-search">
            <HiOutlineArrowLongLeft
              style={{
                fontSize: "60px",
                color: "#1C1C1C",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/departments")}
            />

            <h1 className="subheading text-center">
              {departmentName}{" "}
              <span className="span">({subDepartments.length})</span>
              <img src={micIcon} alt="" className="micIcon" />
            </h1>
          </div>

          <div className="form">
            <span className="left-pan">
              <i
                className="fa fa-thin fa-search"
                style={{ color: "black" }}
              ></i>
            </span>
            <input
              type="text"
              className="search-product"
              placeholder="Enter keyword"
            />
          </div>

          <ul className="product-department-list">
            {subDepartments.map((subDepartment) => (
              <li
              className="dLi"
                key={subDepartment.subDepartmentID}
                onClick={() =>
                  navigate(
                    `/departments/subdepartment/items/${subDepartment.subDepartmentID}`,
                    {
                      state: {
                        subDepartmentName: subDepartment.subDepartmentName,
                      },
                    }
                  )
                }
              >
                <p className="anc">{subDepartment.subDepartmentName}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SubDepartment;
