import { Modal } from "bootstrap";
import React from "react";

const DeleteFavoriteModal = ({ onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    const modal = Modal.getInstance(
      document.getElementById("deleteFavoriteModal")
    );
    modal.hide();
  };

  return (
    <div
      className="modal fade"
      id="deleteFavoriteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <p className="modal-body confirmtext text-center" id="deleteModalLabel">
            Are you sure you want to remove the product from your favorite?
          </p>

          <div className="modal-footer d-flex justify-content-center gap-3">
            <button
              type="button"
              className="btn btn-outline-warning px-5 rounded-4"
              onClick={handleConfirm}
              style={{ fontSize: "18px" }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secondary px-5 rounded-4"
              data-bs-dismiss="modal"
              style={{ fontSize: "18px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFavoriteModal;
