import { useState } from "react";
import axios from "axios";

const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const resetPassword = async (emailID, oldPassword, newPassword) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/ResetPassword/ResetPassword",
        {
          emailID,
          oldPassword,
          newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "LoginToken": "8A5FC4A0-351B-4ED8-99DF-500933B005F4",
          },
        }
      );

     

      const { errorCode, errorDetails } = response.data;

      if (errorCode === 1) {
        setSuccess(true);
      } else {
        setError(errorDetails || "Failed to reset the PIN.");
      }
    } catch (err) {
      if (err.response) {
        
        setError(err.response.data.errorDetails || "Failed to reset PIN.");
      } else if (err.request) {
        console.log("Request error:", err.request);
        setError("Network error. Please try again.");
      } else {
        console.log("General error:", err.message);
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return { resetPassword, loading, error, success };
};

export default useResetPassword;
