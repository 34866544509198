import React, { useState } from "react";
import logo from "../../assets/images/Logo.png";
import { useNavigate } from "react-router-dom";
import useResetPassword from "../../hooks/useResetPin";
import Loader from "../../utility/Loader";
import { FiInfo } from "react-icons/fi";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

const ResetPin = () => {
  const [emailID, setEmailID] = useState("");
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const { resetPassword, loading, error, success } = useResetPassword();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPin !== confirmNewPin) {
      alert("New PIN and confirm new PIN do not match");
      return;
    }

    console.log("Submitting form with:", { emailID, oldPin, newPin });

    await resetPassword(emailID, oldPin, newPin);

    if (success) {
      navigate("/home"); // or wherever you want to redirect after success
    }
  };

  const handleForgotPin = (event) => {
    event.preventDefault();
    navigate("/forgot-pin");
  };

  return (
    <section className="login-form p-0">
      <div className="container-fluid p-lg-0 p-md-0">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="wrap d-md-flex">
              <div className="img d-flex align-items-center justify-content-center">
                <img className="logo-img" src={logo} alt="Logo" />
              </div>

              <div className="login-wrap">
                <div className="form-title w-100 d-flex align-items-center justify-content-lg-between">
                  <h2 className="authheading">Reset pin</h2>
                  <span
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <FiInfo className="fs-2" style={{ color: "D4BB2A" }} />
                    <span className="mt-1">Help</span>
                  </span>
                </div>
                <p className="form-sub-heading">Please reset your pin</p>
                <form onSubmit={handleSubmit} className="m-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User ID"
                      value={emailID}
                      onChange={(e) => setEmailID(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter current PIN"
                      value={oldPin}
                      onChange={(e) => setOldPin(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter new PIN"
                      value={newPin}
                      onChange={(e) => setNewPin(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Re-enter new PIN"
                      value={confirmNewPin}
                      onChange={(e) => setConfirmNewPin(e.target.value)}
                      required
                    />
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  {success && (
                    <div className="alert alert-success">
                      PIN reset successfully!
                    </div>
                  )}
                  <div className="form-footer d-flex justify-content-between align-items-center">
                    <button
                      type="submit"
                      className="btn-two"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Reset PIN"}
                    </button>
                    <p className="text-center m-note">
                      <a href="#forgot-pin" onClick={handleForgotPin}>
                        Forgot pin?
                      </a>
                    </p>
                  </div>

                  <div className="pt-3 d-flex align-items-center">
                    <button
                      className="btn-clear"
                      onClick={() => navigate("/settings")}
                    >
                      <HiOutlineArrowLongLeft
                        style={{
                          fontSize: "45px",
                          color: "#D4AC0D",
                          marginRight: "10px",
                        }}
                      />
                      <p
                        className="text-center m-0"
                        style={{ fontSize: "20px", fontWeight: "normal" }}
                      >
                        Go back to settings
                      </p>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPin;
